import React, { useEffect, useState } from 'react'
import Cookies from "js-cookie";
import axios from 'axios'

const WalletHist = () => {
    const [data, setData] = useState()

    useEffect(() => {
        const getData = async () => {
            const CID = Cookies.get("CID");
            try {
                const resp = await axios.get("https://dlunchbox.com/userWalletHistory", {
                    params: { CID }
                });

                if (resp.status === 200) {
                    // Extract data from response
                    const data = resp.data;

                    // Function to format timestamp
                    const formatTimestamp = (timestamp) => {
                        // Split the timestamp at 'T' and return the first part (date) and the second part (time)
                        const [date, time] = timestamp.split('T');
                        return `${date} `; // Remove milliseconds
                    };

                    // Update each entry in the data to format the timestamp
                    const updatedData = data.map(item => ({
                        ...item,
                        time_stamp: formatTimestamp(item.time_stamp)
                    }));

                    // Store the updated data in the state
                    setData(updatedData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };


        getData()
    }, [])

    return (
        <div>
            <p className='text-2xl text-center mt-2'>Wallet History</p>

            <div className='mt-4 border border-solid px-4 py-4 rounded border-gray-400 overflow-auto max-h-80'>
                {data?.map((data, index) => {
                    return <div key={index} className='flex flex-row justify-start gap-12 border border-solid px-2 border-gray-400 rounded mb-2'>
                        <p className='text-gray-400'>{index + 1}.</p>
                        <p className=''>Rs. {data.amount}</p>
                        <p className={`${data.type === 'added' ? 'text-green-600' : 'text-red-600'}`}>{data.type}</p>
                        <p className=''>{data.time_stamp}</p>
                    </div>

                })}
            </div>
        </div>
    )
}

export default WalletHist;
import React from 'react'
import HowCard from './HowCard';

import img1 from '../asset/how1.png';
import img2 from '../asset/how2.png';
import img3 from '../asset/how3.png';

const How = (props) => {
    return (
        <div className='mt-20 mb-12'>
            <p className='text text-center text-lg text-red-600 font-medium'>Our Work Process</p>
            <p className='text text-center text-3xl mb-12 mt-4 font-bold'>How It Work</p>

            <div className='flex flex-col lg:flex-row justify-center gap-40'>
                <HowCard image={img1} head="Choose your Meals" txt="Craft your perfect meal for just Rs.90: Customize your lunch box, your way!"></HowCard>
                <HowCard image={img2} head="Faster Delivery" txt="From kitchen to doorstep in a flash: lunchtime satisfaction guaranteed!"></HowCard>
                <HowCard image={img3} head="We Cook & Deliver Faster" txt="Savor the flavor of health: Where delicious meets nutritious in every bite!"></HowCard>
            </div>
        </div>
    )
}

export default How;
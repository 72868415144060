import React, { useEffect, useState } from 'react'
import axios from 'axios'

import bin from '../asset/bin.png'
import MenuDeletePop from './popup/MenuDeletePop'


const Menu = () => {
    const [ispopupOpen, setIsPopupOpen] = useState(false)
    const [deleteId, setdeleteId] = useState()
    const [dummy, setDummy] = useState(false)
    const [dishImg, setDishImg] = useState('')
    const [menuData, setMenuData] = useState()
    const [list, setList] = useState(false);
    const [menu, setMenu] = useState({
        dishname: "",
        mealtype: "",
        description: "",
        category: ""
    });

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const getMenu = async () => {
        const token = localStorage.getItem("token")
        try {
            const res = await axios.get("https://dlunchbox.com/menu", {
                headers: {
                    Authorization: token,
                },
            })
            console.log(res.data)
            setMenuData(res.data)
        } catch (err) {
            console.log(err)
        }
    }



    useEffect(() => {
        const val = localStorage.getItem("list")
        if (val === 'true') {
            localStorage.setItem("list", "true")

            getMenu()
        } else {
            localStorage.setItem("list", "false")
        }
    }, [dummy])

    const openDeleteDish = (dishId) => {
        setIsPopupOpen(true)
        setdeleteId(dishId)
    }

    const dishDeleteHnd = async (dishId) => {
        console.log(dishId, "delete")

        try {
            const res = await axios.delete("https://dlunchbox.com/deletedish", { params: { dishId } })

            if (res.status == '200') {
                alert("deleted successfully")
                setIsPopupOpen(false)
                setDummy(!dummy)
            } else {
                alert("please try again")
            }
        } catch (err) {
            console.log(err)
        }
    }

    const hndChg = (e) => {
        setMenu({ ...menu, [e.target.name]: e.target.value })

        if (e.target.name === 'dishImg') {
            setDishImg(e.target.files[0])

        }
    }

    const clkHnd = (e) => {
        setMenu({ ...menu, [e.target.name]: e.target.value })
    }

    const showHnd = async (e) => {

        if (e.target.name === 'form') {
            setList(false)
            localStorage.setItem("list", "false")
        }

        if (e.target.name === 'list') {
            setList(true)
            localStorage.setItem("list", "true")
            try {
                const res = await axios.get("https://dlunchbox.com/menu")
                console.log(res.data)
                setMenuData(res.data)
            } catch (err) {
                console.log(err)
            }

        }

    }

    const activateHnd = async (status, id) => {
        //console.log(status)
        try {
            const res = await axios.put("https://dlunchbox.com/dishStatus", { status, id })
            if (res.status === 200) {
                setDummy(!dummy)
            }
        } catch (err) {
            console.log(err)
        }
    }


    const hndSbt = async (e) => {
        e.preventDefault();
        console.log(menu)
        console.log(dishImg)

        const formdata = new FormData()
        formdata.append("dishname", menu.dishname)
        formdata.append("mealtype", menu.mealtype)
        formdata.append("description", menu.description)
        formdata.append("category", menu.category)
        formdata.append("dishImg", dishImg)

        try {
            const res = await axios.post("https://dlunchbox.com/menuList", formdata)

            if (res.status == '200') {
                alert('Added Successfully')
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
        }
    }

    const listValue = localStorage.getItem("list")

    return (
        <div className='mt-24  pb-12'>
            <p className='text-center text-3xl font-semibold'>Lunch Box Menu</p>

            <div className='flex flex-row justify-center gap-12 mt-4 mb-8'>
                <button onClick={showHnd} name='form' className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${(listValue === "false" || list === false) ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                    }`} >Menu Form</button>
                <button onClick={showHnd} name='list' className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${listValue === "true" ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                    }`} >Menu List</button>

            </div>

            {(listValue === "false" || !list) && <div className='bg-gradient-to-r from-blue-100 via-#00f2fe to-orange-100 mt-12 mb-8 border border-solid border-gray-400  mx-auto block w-9/12'>
                <form onSubmit={hndSbt} className=' py-4 rounded-md mb-8'>
                    <div className='flex flex-row justify-center gap-8 mt-4'>
                        <p>Dish Name:</p>
                        <input onChange={hndChg} name='dishname' type='text' placeholder='Dish Name'
                            className='border text-center border-solid rounded-lg border-gray-400'></input>
                    </div>

                    <div className='flex flex-row justify-center gap-8 mt-8'>
                        <p>Meal Type:</p>
                        <button className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${menu.mealtype === 'Veg' ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                            }`} onClick={clkHnd} name='mealtype' value='Veg' type='button'>Veg</button>
                        <button className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${menu.mealtype === 'Nonveg' ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                            }`} onClick={clkHnd} name='mealtype' value='Nonveg' type='button'>Non Veg</button>
                    </div>

                    <div className='flex flex-row justify-center gap-8 mt-8'>
                        <p>Description:</p>
                        <textarea onChange={hndChg} className=' rounded-lg text-center border border-solid border-gray-400' name='description' placeholder='Description'></textarea>
                    </div>

                    <div className='flex flex-row justify-center gap-8 mt-8'>
                        <p>Category:</p>
                        <button className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${menu.category === 'bread' ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                            }`} onClick={clkHnd} name='category' value='bread' type='button'>Bread</button>

                        <button className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${menu.category === 'rice' ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                            }`} onClick={clkHnd} name='category' value='rice' type='button'>Rice</button>

                        <button className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${menu.category === 'MaharashtrianVegetable' ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                            }`} onClick={clkHnd} name='category' value='MaharashtrianVegetable' type='button'>Maha Vegetable</button>

                        <button className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${menu.category === 'NorthIndianVegetable' ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                            }`} onClick={clkHnd} name='category' value='NorthIndianVegetable' type='button'>North Vegetable</button>

                        <button className={`px-4 py-2 rounded-md border border-solid border-gray-400 ${menu.category === 'sweet' ? 'bg-green-500 text-white' : 'bg-gray-100 text-black'
                            }`} onClick={clkHnd} name='category' value='sweet' type='button'>Sweet</button>
                    </div>

                    <div className='flex flex-row justify-center gap-8 mt-8'>
                        <p>Add Image:</p>
                        <input onChange={hndChg} type='file' accept="image/*" name="dishImg" ></input>
                    </div>

                    <button className='text-white rounded-md mx-auto block my-8 bg-green-600 px-12 py-2' type='submit'>Submit</button>
                </form>
            </div>}


            {listValue === "true" && <div className='flex flex-row justify-center gap-4 flex-wrap'>
                {menuData?.map((dish, index) => {
                    return <div key={index} className='relative border border-solid border-gray-400
                    rounded-md mb-4 w-3/12 bg-white'>

                        <div className='flex justify-end'>
                            <button onClick={() => { openDeleteDish(dish.id) }} className='mr-2 mt-2 hover:border border-solid border-red-600'><img src={bin} className='w-5'></img></button>


                        </div>

                        <p className='text-center mt-2 mb-2 font-semibold'>{dish.dishName}
                            <span className='text-sm text-gray-500 ml-4'>{dish.mealType}</span></p>


                        <p className='text-sm text-center mx-2 mb-2 text-gray-500'>{dish.description}</p>
                        <p className='text-center text-sm'>Category: {dish.category}</p>

                        <div className='mt-24'>
                            <button onClick={() => { activateHnd(dish.status, dish.id) }} className={`absolute bottom-2 left-8 right-8 px-4 py-2   mb-2 rounded-md border border-solid ${dish?.status === 0 ? 'bg-green-500 text-white' : 'bg-red-500 text-black'
                                }`} >{dish?.status === 0 ? 'Activate' : 'Deactivate'}</button>
                        </div>
                    </div>
                })}
            </div>}

            {ispopupOpen && <MenuDeletePop deleteId={deleteId} dishDeleteHnd={dishDeleteHnd} onClose={closePopup}></MenuDeletePop>}

        </div>


    )
}

export default Menu;
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import bin from '../asset/bin.png'

import MenuDeletePop from './popup/MenuDeletePop'

const DeliveryPins = () => {
    const [pinData, setPinData] = useState()
    const [pin, setPin] = useState()
    const [submitting, setSubmitting] = useState(false)
    const [ispopupOpen, setIsPopupOpen] = useState(false)
    const [deleteId, setDeleteId] = useState()

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const hndChange = (e) => {
        setPinData(e.target.value)
    }

    const deleteHnd1 = (id) => {
        setIsPopupOpen(true)
        setDeleteId(id)
    }

    const deleteHnd2 = async (id) => {
        const token = localStorage.getItem("token")

        try {
            const res = await axios.delete("https://dlunchbox.com/pinRemove", {
                params: { id },
                headers: {
                    Authorization: token,
                },
            });

            if (res.status == 200) {
                setIsPopupOpen(false)
                alert('Success')
                window.location.reload()
            }
        } catch (err) {
            console.log(err)
            alert("BAD REQUEST")
            setIsPopupOpen(false)
        }
    }

    const HndSubmit = async (e) => {
        e.preventDefault()

        try {
            const token = localStorage.getItem("token")
            setSubmitting(true)
            const res = await axios.post("https://dlunchbox.com/setNonDelPincodes", { pinData }, {
                headers: {
                    Authorization: token,
                }
            })

            if (res.status == 200) {
                setSubmitting(false)
                alert('Added Successfully')
                window.location.reload()
            } else if (res.status == 400) {
                setSubmitting(false)
                alert('Network Error')
            } else if (res.status == 204) {
                alert('Pincode Already In The List')
                setSubmitting(false)
            }
        } catch (err) {
            console.log(err)
            setSubmitting(false)
            alert('Network Error')
        }
    }

    useEffect(() => {
        const fetchPincodes = async () => {
            try {
                const res = await axios.get("https://dlunchbox.com/nonDelPincodes");
                if (res.status == 200) {
                    console.log(res.data)
                    setPin(res.data)
                }

            } catch (err) {
                console.log(err)
            }


        }

        fetchPincodes()
    }, [])

    return (
        <div className='mt-24'>


            <p className='text-center'>Add Pincode To Non-Delivery List</p>
            <div className='border shadow-xl border-solid border-gray-200 w-5/12 rounded-xl mx-auto block px-4 py-4 mt-4'>
                <form onSubmit={HndSubmit}>
                    <input onChange={hndChange} name='pincode' placeholder='Enter Pincode' className='text-center rounded border border-solid border-gray-400 w-full'></input><br></br>
                    <button type='submit' disabled={submitting} className={` ${submitting ? "bg-red-300" : "bg-red-600"} mt-8 w-full py-3 rounded hover:bg-red-700 text-white`}>{submitting ? "Wait.." : "Submit"}</button>
                </form>
            </div>

            <div className='mt-12'>
                <p className='text-center'>Below Is The List Of Pincodes Added To Non-Delivery Area</p>

                <div className='shadow-xl flex flex-row justify-center gap-4 my-8 py-4 mx-8 rounded-xl'>
                    {pin?.map((pins, index) => {
                        return <div key={index} className='flex flex-row justify-center gap-4 px-2 rounded py-1 border border-solid border-gray-300'>
                            <p className=''>{pins.pin}</p>
                            <button onClick={() => { deleteHnd1(pins.id) }}>
                                <img src={bin} className='w-4 h-4 '></img>
                            </button>

                        </div>
                    })}
                </div>
            </div>

            {ispopupOpen && <MenuDeletePop deleteId={deleteId} dishDeleteHnd={deleteHnd2} onClose={closePopup}></MenuDeletePop>}
        </div>
    )
}

export default DeliveryPins;
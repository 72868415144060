import React from "react";
import { useNavigate } from "react-router-dom";

const CareerPage = () => {
    const Navigate = useNavigate();

    return (
        <div className="mt-20 mx-auto max-w-3xl px-4">
            <div className="text-center">
                <h1 className="text-4xl font-semibold text-gray-800">Join Our Team</h1>
                <p className="mt-4 text-gray-600">
                    We're always on the lookout for talented individuals to join us in
                    reshaping the future of DLunchBox.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
                <div className="bg-white rounded-lg shadow-md">
                    <div className="p-6">
                        <h2 className="text-xl font-semibold text-gray-800">
                            Sales and Marketing Executive
                        </h2>
                        <p className="mt-2 text-gray-600">
                            We are looking for dynamic individuals to join our sales and marketing team. If you have a passion for promoting products and driving sales, we'd love to hear from you.
                        </p>
                    </div>
                    <div className="p-4 bg-gray-100">
                        <button
                            onClick={() => {
                                Navigate("/contact");
                            }}
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                        >
                            Apply Now
                        </button>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-md">
                    <div className="p-6">
                        <h2 className="text-xl font-semibold text-gray-800">Accounting Executive</h2>
                        <p className="mt-2 text-gray-600">
                            Are you detail-oriented and skilled in financial management? Join our accounting team and help ensure the financial health of DLunchBox with your expertise.
                        </p>
                    </div>
                    <div className="p-4 bg-gray-100">
                        <button
                            onClick={() => {
                                Navigate("/contact");
                            }}
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                        >
                            Apply Now
                        </button>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-md">
                    <div className="p-6">
                        <h2 className="text-xl font-semibold text-gray-800">Delivery Boy</h2>
                        <p className="mt-2 text-gray-600">
                            Do you enjoy being on the move and providing excellent service? Join our delivery team and play a crucial role in ensuring our customers receive their orders promptly and efficiently.
                        </p>
                    </div>
                    <div className="p-4 bg-gray-100">
                        <button
                            onClick={() => {
                                Navigate("/contact");
                            }}
                            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                        >
                            Apply Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CareerPage;

import React from 'react'
import team from '../../pages/asset/about1.jpg';
import facebook from '../../pages/asset/facebook.png'
import insta from '../../pages/asset/instagram.png'
import linkedIn from '../../pages/asset/linkedin.png'

const Inside = () => {
    return (
        <div>
            <div className='flex flex-row justify-center mt-24 text-white'>
                <div className='w-6/12 bg-purple-700  flex justify-center items-center'>
                    <div className=''>
                        <p className='text-2xl text-center mt-4'>We Are DLunchBox | <b>The Inside Scoop</b></p>
                        <p className='mx-12 mt-8'>Want to know what's buzzing at the DLunchBox side of the planet? There here is what you need to follow. We Are DLunchBox channels on Social Media will give you an inside-out view of the everyday serious and fun stuff within our world. All you need to do is to click your preferred channel and make sure you follow us.</p>

                        <div className='flex flex-row justify-center gap-4 mt-8'>
                            <img src={facebook} className='w-8'></img>
                            <img src={insta} className='w-8'></img>
                            <img src={linkedIn} className='w-8'></img>
                        </div>
                    </div>
                </div>
                <div className='w-6/12 '>
                    <img src={team} className=''></img>
                </div>
            </div>
        </div>
    )
}

export default Inside;
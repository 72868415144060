import classes from "./MyOffer.module.css";
import axios from "axios"
import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
axios.defaults.withCredentials = true;


const MyOffer = () => {
    const [offers, setOffers] = useState([]);
    const get_offer_details = (ID) => {

        Cookies.set('my_offers', ID);

        const newTabUrl = 'https://dlunchbox.com/getOfferDetails';

        // Open a new tab with the specified URL
        window.open(newTabUrl, '_blank');
    }
    const getAllOffers = async () => {
        try {
            const res = await axios.get("https://dlunchbox.com/offers")
            console.log(res)
            setOffers(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAllOffers();
    }, []);

    /**Property Slider**/
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    return (
        <>
            <div className={classes.container}>
                <Carousel
                    autoPlay={true}
                    showDots={true}
                    infinite={true}
                    pauseOnHover={true}
                    responsive={responsive}
                    arrows={false}
                    autoPlaySpeed={5000}
                >

                    {offers && offers?.map((item, index) => (
                        <>
                            <div className={classes.card} key={index}>
                                <h1>{item.PACK_NAME}</h1>
                                <p className={classes.discription}>{item.SUB_DESC}</p>
                                <p className={classes.pricetag}>@ {item.PRICE}</p>
                                <button className={classes.getoffer} onClick={() => get_offer_details(item.ID)}>Get Offer Details</button>
                            </div>
                        </>
                    ))}
                </Carousel>
            </div>
        </>
    );
};
export default MyOffer;
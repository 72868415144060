import React, { useEffect, useState } from 'react'
import axios from 'axios'

const Pincode = () => {
    const [date, setDate] = useState()
    const [data, setData] = useState()

    const getData = async () => {
        const token = localStorage.getItem("token")
        const dateValue = getTodayDate()
        try {
            const res = await axios.get("https://dlunchbox.com/pincode", {
                params: { dateValue },
                headers: {
                    Authorization: token,
                },
            })
            console.log(res.data)
            setData(res.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    function handleDateChange(event) {
        setDate(event.target.value);
        getDateWiseList(event.target.value)
    }

    const getDateWiseList = async (dateValue) => {
        const token = localStorage.getItem("token")
        //console.log(dateValue, " date")

        try {
            const res = await axios.get("https://dlunchbox.com/pincode", { params: { dateValue } }, {
                headers: {
                    Authorization: token,
                },
            })
            console.log(res.data)
            setData(res.data)



        } catch (err) {
            console.log(err)
        }
    }

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Adding leading zeros if month or day are less than 10
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }

    return (
        <div>
            <div className=''>
                <div className='flex flex-row justify-center gap-12 py-4 bg-blue-200'>
                    <label>Select A Date:</label>
                    <input onChange={handleDateChange} id="date" type='date' name="date" value={date}
                        className='border border-solid border-blue-800 w-44 text-center'></input>
                </div>
            </div>

            <div>
                {data?.map((address, index) => {
                    return <div key={index} className='flex flex-row flex-wrap text-lg justify-left ml-4 gap-4 border-b border-gray-400 my-4'>
                        <p>no. {address.id}</p>
                        <p><b>name:</b> {address.name}</p>
                        <p><b>mobile:</b> {address.mobno}</p>
                        <p><b>address: </b>{address.deliveryaddress},</p>
                        <p><b>village:</b>{address.village},</p>
                        <p><b>city:</b>{address.city}</p>
                        <p><b>pincode:</b>{address.pincode}</p>
                        <p><b>landmark: </b>{address.landmark}</p>
                        <p><b>location: </b>{address.current_location}</p>
                        <p><b>deliveryTime:</b> <span>{address.deliverytime}</span></p>
                        <p><b>Type: </b>{address.mealtype}</p>
                        <p><b>LunchBox: </b>{address.vegetable1},{address.vegetable2},
                            {address.bread},{address.rice},{address.sweet}</p>

                    </div>
                })}
            </div>
        </div>
    )
}

export default Pincode;
import classes from "./DeletePopup.module.css";

const DeletePopup = ({txt1, txt2,onClose,onDelete}) =>{
    return(
        <div className={classes.container}>
            <div className={classes.popupContainer}>
                <h2 className={classes.hed}>{txt1}</h2>
                <p>{txt2}</p>
                <div className={classes.btnContainer}>
                    <button className={classes.close} onClick={onClose}>Close</button>
                    <button className={classes.yes} onClick={onDelete}>Delete</button>
                </div>
            </div>
        </div>
    );
};
export default DeletePopup;
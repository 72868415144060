import React, { useState } from 'react'
import WalletHistory from './WalletHistory'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Wallet = () => {
    const [mode, setMode] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [criteria, setCriteria] = useState(2)
    const [data, setData] = useState({
        mobile: '',
        amount: '',
        reason: '',
    })

    const navigate = useNavigate()

    const dataHnd = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const modeHnd = (e) => {
        if (e.target.name == 'add') {
            setMode('ADD MONEY')
        } else {
            setMode('DEDUCT MONEY')
        }
    }

    const submitHnd = async (e) => {
        e.preventDefault()
        //console.log(mode, ' mode')
        //console.log(data, " data")

        try {
            const token = localStorage.getItem("token")

            setIsSubmitting(true)
            const res = await axios.post("https://dlunchbox.com/adminWallet", { data, mode }, {
                headers: {
                    Authorization: token,
                },
            })

            if (res.status == 200) {
                alert('success')
                setIsSubmitting(false)
            } else {
                alert('failed')
                setIsSubmitting(false)
            }
        } catch (err) {
            //console.log(err)
            navigate('/login')
        }
    }

    return (
        <div className='mt-24'>
            <p className='text-2xl mb-4 font-semibold text-center'>Wallet Section</p>

            <div className='flex flex-row gap-4'>
                <div className='bg-orange-200 py-2 px-2 flex flex-col w-2/12 ml-4 border border-solid border-gray-600'>
                    <button onClick={() => { setCriteria(1) }} className='bg-orange-500 mb-4 rounded'>Add money</button>
                    <button onClick={() => { setCriteria(2) }} className='bg-orange-500 mb-4 rounded'>Wallet History</button>
                </div>
                <div className='border border-solid w-10/12 bg-gray-200 py-4'>
                    {criteria === 1 && <div className='mx-auto block w-6/12'>
                        <form onSubmit={submitHnd} className='mx-auto block'>
                            <p className='text-center mb-4 text-black'>Fill Form To Add/Deduct Money From User Wallet</p>
                            <input name='mobile' onChange={dataHnd} type='number' className='rounded mx-auto block text-center border border-solid mb-4' placeholder='mobile number'></input><br></br>
                            <input name='amount' onChange={dataHnd} type='number' className='rounded mx-auto block text-center border border-solid mb-4' placeholder='amount'></input><br></br>
                            <textarea name='reason' onChange={dataHnd} className='mx-auto block w-72 mb-4' placeholder='Reason to add/deduct money'></textarea><br></br>
                            <div className='flex flex-row justify-center gap-4 mb-4'>
                                <button name='add' type='button' onClick={modeHnd} className={`rounded border border-solid border-gray-500 px-3 py-3 ${mode == 'ADD MONEY' ? 'bg-orange-500' : 'bg-gray-300'}`}>ADD MONEY</button>
                                <button name='deduct' type='button' onClick={modeHnd} className={`rounded border border-solid border-gray-500 px-3 py-3 ${mode == 'DEDUCT MONEY' ? 'bg-orange-500' : 'bg-gray-300'}`}>DEDUCT MONEY</button>
                            </div>
                            <button type='submit' disabled={isSubmitting} className={`${isSubmitting ? "bg-green-300" : "bg-green-500"} mx-auto block w-72 h-12 rounded`}>{isSubmitting ? 'Wait..' : 'Submit'}</button>
                        </form>
                    </div>}
                    {criteria === 2 && <WalletHistory></WalletHistory>}
                </div>
            </div>


        </div>
    )
}

export default Wallet;
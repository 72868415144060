import React, { useEffect, useState } from 'react'
import Hero from '../components/Home/Hero/Hero';
import Hero2 from '../components/Home/Hero/Hero2';
import How from '../components/Home/How/How';
import Category from '../components/Home/Category/Category';
import Whyus from '../components/Home/Whyus';
import Test from '../components/Home/testimonial/Test';
import Qr from '../components/qrcode/Qr';
import MyOffer from '../components/MY OFFER/MyOffer';

import MsgPopup from '../MsgPopup';


const Homepage = () => {
    const [show, setShow] = useState(false)

    const msgHnd = () => {
        setShow(true)
    }

    const msgHnd2 = () => {
        setShow(false)
    }

    useEffect(() => {
        setTimeout(msgHnd, 2000)
        setTimeout(msgHnd2, 60000)
    }, [])

    const closeHnd = () => {
        setShow(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div>
            {/* {show && <MsgPopup close={closeHnd}></MsgPopup>} */}
            <Hero2></Hero2>
            <MyOffer></MyOffer>

            <How></How>
            <Category></Category>
            <Whyus></Whyus>
            {/* <Qr></Qr> */}
            <Test></Test>

        </div>
    )
}

export default Homepage;
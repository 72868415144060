import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../Nav/logo.png';
import phone from './asset/smartphone.png';
import mail from './asset/email.png';
import facebook from './asset/facebook.png';
import instagram from './asset/instagram.png';
import youtube from './asset/youtube.png';
import location from './asset/location.png';
import google from './asset/google.png';
import appleStore from './asset/appleStore.png';
import Cookies from 'js-cookie';

const Footer = () => {
    const adminSuccess = Cookies.get("ADMINSUCCESS");

    useEffect(() => {
        //checkAdminFunction()
    })

    return (
        <div className='py-8 bg-gray-50 mt-12 border-t border-gray-500'>
            <div className='flex flex-col lg:flex-row justify-center lg:gap-12 px-8'>
                <div className='lg:w-4/12 text-center lg:text-left -mt-11'>
                    <img src={logo} className='w-52 mx-auto block'></img>
                    <p className='text-gray-600'>We deliver convenience and flavor with every click, bringing deliciousness right to your doorstep and making lunchtime a breeze!</p>

                    <div className='flex flex-row   gap-2 mt-4 justify-center lg:justify-start'>
                        <a target="_blank" href='https://www.facebook.com/profile.php?id=61558565073680'><img src={facebook} className='w-8'></img></a>
                        <a target="_blank" href='https://www.instagram.com/dlunchbox24/'><img src={instagram} className='w-8'></img></a>
                        <a target="_blank" href='https://www.youtube.com/@dlunchbox'><img src={youtube} className='w-8'></img></a>
                    </div>
                </div>

                <div className='lg:w-4/12 w-12/12 text-center mt-8 lg:mt-0'>
                    <p className='text-2xl font-medium mb-4'>Company</p>
                    <Link to="/"><p className='mb-2 text-gray-600'>Home</p></Link>
                    <Link to="/about"><p className='mb-2 text-gray-600'>About Us</p></Link>
                    <Link to="/career"><p className='mb-2 text-gray-600'>Join Us</p></Link>
                    <Link to='/contact'><p className='mb-2 text-gray-600'>Contact Us</p></Link>
                    {adminSuccess === "true" && <Link to='/adminDashboard'><p className='mb-2 text-gray-600'>Dashboard</p></Link>}
                </div>

                <div className='w-12/12 lg:w-4/12 text-center mt-8 lg:mt-0'>
                    <p className='text-2xl font-medium mb-4'>Information</p>
                    <Link to='/PrivacyPolicy'><p className='mb-2 text-gray-600'>Privacy Policy</p></Link>
                    <Link to='/TermsAndConditions'><p className='mb-2 text-gray-600'>Terms & Conditions</p></Link>
                    <Link to='/CancelPolicy'><p className='mb-2 text-gray-600'>Cancellation & Refunds</p></Link>
                </div>

                <div className='w-12/12 lg:w-4/12 text-center mt-8 lg:mt-0'>
                    <p className='text-2xl font-medium mb-4'>Contact Us</p>
                    <div className='flex flex-row items-center justify-center lg:justify-start'>
                        <img src={phone} className='w-6 h-6'></img>
                        <p className='text-1xl ml-4 text-gray-600'>8600028102</p>
                    </div>

                    <div className='mt-2 flex flex-row items-center justify-center lg:justify-start'>
                        <img src={phone} className='w-6 h-6'></img>
                        <p className='text-1xl ml-4 text-gray-600'>8600029102</p>

                    </div>
                    <p className='text-sm -ml-12 '> (Customer Care <br></br> 9:00 am TO 9:00 pm)</p>

                    <div className='flex flex-row items-center justify-center lg:justify-start mt-2'>
                        <img src={mail} className='w-6 h-6'></img>
                        <p className='text-1xl ml-4 text-gray-600'>info@dlunchbox.com</p>
                    </div>

                    <div className='flex flex-row items-center justify-center lg:justify-start mt-2'>
                        <img src={location} className='w-6 h-6'></img>
                        <p className='text-1xl ml-4 text-gray-600'>Nirvana Club, At Viva Inn Building, Dr. Homi Bhabha Rd., Bavdhan Chandini, Chawk, Pune 411021</p>
                    </div>
                </div>
            </div>
            <hr></hr>

            <div className='flex flex-col lg:flex-row justify-center gap-12 mt-4'>
                <p className='mt-4 text-center lg:text-left lg:w-4/12'>copyright © 2024 DLunch Box | 2024 Powered By Exads IT Solutions</p>
                <p className='text-3xl mt-4 text-center lg:text-left lg:w-4/12'>Download Now</p>
                <div className="flex justify-center lg:justify-start lg:w-4/12">
                    <button><img src={google} className='w-32 h-12'></img></button>
                    <div style={{ width: '2rem' }}></div>
                    <button><img src={appleStore} className='w-32 h-12'></img></button>
                </div>
            </div>
        </div>
    )
}

export default Footer;

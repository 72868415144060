// TermsAndConditions.jsx

import React, { useEffect } from 'react';
import './TermsAndConditions.css';


const TermsAndConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="terms-and-conditions">
            <br></br><br></br><br></br><br></br>
            <h1>Terms And Conditions</h1>
            <p>
                These Terms and Conditions ("Terms") govern your use of the lunch delivery services provided by DLunch Box ("we", "us", or "our") through our website. By accessing or using our Website and services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our Website or services.
            </p>
            <h2>1. Services</h2>
            <p>
                1.1. We provide a platform for users to order lunch from a variety of participating restaurants and have it delivered to their location.
            </p>
            <p>
                1.2. By placing an order through our Website, you agree to pay the full amount for the order, including any applicable taxes, delivery fees, and tips.
            </p>
            <h2>2. Ordering and Payment</h2>
            <p>
                2.1. You must be at least 18 years old to place an order through our Website.
            </p>
            <p>
                2.2. Payment for orders must be made using the methods specified on our Website. By providing your payment information, you authorize us to charge the total amount of your order.
            </p>
            <p>
                2.3. Prices and availability of menu items are subject to change without notice.
            </p>
            <h2>3. Delivery</h2>
            <p>
                3.1. We aim to deliver orders within the estimated time provided on our Website, but delivery times may vary depending on factors such as traffic and weather conditions.
            </p>
            <p>
                3.2. We are not responsible for delays in delivery caused by circumstances beyond our control.
            </p>
            <p>
                3.3. If you are not available to receive your order at the delivery address provided, we reserve the right to charge you for the full amount of the order.
            </p>

            <p>
                3.4. DLunchBox will be delivered within 0 to 1 day or as per time scheduled by the organisation.
            </p>
            <h2>4. Cancellations and Refunds</h2>
            <p>
                4.1. You may cancel an order without charge up to a certain time specified on our Website.
            </p>
            <p>
                4.2. Refunds for cancelled orders or orders that cannot be fulfilled will be processed in accordance with our Refund Policy.
            </p>
            <h2>5. Changes to Terms</h2>
            <p>
                5.1. We reserve the right to modify or update these Terms at any time by posting the revised terms on our Website. Your continued use of our Website and services following the posting of any changes constitutes acceptance of those changes.
            </p>
        </div>
    );
};

export default TermsAndConditions;

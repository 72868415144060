import { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import Cookies from 'js-cookie';

import Homepage from './pages/Homepage'
import Aboutpage from './pages/Aboutpage'
import Login from './pages/Login';
import Signup from './pages/Signup';
import Terms from './pages/Terms';
import CareerPage from './pages/CareerPage';
import AdminDashboard from './pages/Admin/AdminDashboard';
import Privacy from './pages/Privacy';
import ContactUs from './pages/ContactUS';
import Userdashboard from './pages/user/Userdashboard';
import Root from './pages/Root';
import Dashboard from './components/USERDASH/Dashboard';
import Dashboard2 from './components/USERDASH/Dashboard2';
import Password from './pages/Admin/Password';
import DeliveryPins from './pages/Admin/DeliveryPins';

import CancelPolicy from '../src/components/Footer/terms/CanclePolicy'
import TermsAndConditions from './components/Footer/terms/TermsAndConditions';
import PrivacyPolicy from './components/Footer/terms/PrivacyPolicy'
import UserList from './pages/Admin/UserList';
import MyOfferDetails from './components/MY OFFER/MyOfferDetails';
import Menu from './pages/Admin/Menu';
import Wallet from './pages/Wallet';

const adminSuccess = Cookies.get("ADMINSUCCESS");

const router = createBrowserRouter([{
  path: '/',
  element: <Root></Root>,

  children: [
    { path: '/', element: <Homepage></Homepage> },
    { path: '/about', element: <Aboutpage></Aboutpage> },
    { path: '/login', element: <Login></Login> },
    { path: '/signup', element: <Signup></Signup> },
    { path: '/terms', element: <Terms></Terms> },
    { path: '/career', element: <CareerPage></CareerPage> },
    { path: '/privacy', element: <Privacy></Privacy> },
    { path: '/contact', element: <ContactUs></ContactUs> },
    { path: '/dashboard', element: <Dashboard2></Dashboard2> },
    { path: '/adminDashboard', element: adminSuccess === 'true' ? <AdminDashboard></AdminDashboard> : <Login /> },
    { path: '/usersList', element: <UserList></UserList> },
    { path: '/wallet', element: <Wallet></Wallet> },

    { path: '/CancelPolicy', element: <CancelPolicy></CancelPolicy> },
    { path: '/TermsAndConditions', element: <TermsAndConditions></TermsAndConditions> },
    { path: '/PrivacyPolicy', element: <PrivacyPolicy></PrivacyPolicy> },
    { path: '/getOfferDetails', element: <MyOfferDetails /> },
    { path: '/menuPage', element: <Menu></Menu> },
    { path: '/password', element: <Password></Password> },
    { path: '/DeliveryPins', element: <DeliveryPins /> },

  ]
}]);



const App = () => {

  //to avoid inspect ###

  useEffect(() => {
    // Disable right-click context menu
    const disableContextMenu = (event) => event.preventDefault();
    document.addEventListener('contextmenu', disableContextMenu);

    // Disable keyboard shortcuts
    const disableKeyboardShortcuts = (event) => {
      // Prevent F12
      if (event.keyCode === 123) {
        event.preventDefault();
      }
      // Prevent Ctrl+Shift+I (Windows/Linux) or Cmd+Option+I (Mac)
      if ((event.ctrlKey && event.shiftKey && event.keyCode === 73) ||
        (event.metaKey && event.altKey && event.keyCode === 73)) {
        event.preventDefault();
      }
      // Prevent Ctrl+Shift+J (Windows/Linux) or Cmd+Option+J (Mac) for console
      if ((event.ctrlKey && event.shiftKey && event.keyCode === 74) ||
        (event.metaKey && event.altKey && event.keyCode === 74)) {
        event.preventDefault();
      }
      // Prevent Ctrl+U (Windows/Linux) or Cmd+Option+U (Mac) for view source
      if ((event.ctrlKey && event.keyCode === 85) ||
        (event.metaKey && event.keyCode === 85)) {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', disableKeyboardShortcuts);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', disableContextMenu);
      document.removeEventListener('keydown', disableKeyboardShortcuts);
    };
  }, []);

  return <RouterProvider router={router}></RouterProvider>

};


export default App;
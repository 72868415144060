import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Extra from '../USERDASH/Extra';

import SubmitPopup from './SubmitPopup';
import order from './asset/order.webp'


const NewFoodForm = () => {
    const [data, setData] = useState()
    const [step, setStep] = useState(1)
    const [mealType, setMealType] = useState()
    const [veg1, setVeg1] = useState([])
    const [veg2, setVeg2] = useState([])
    const [breadType, setBreadType] = useState([])
    const [riceType, setRiceType] = useState([])
    const [sweetType, setSweetType] = useState([])
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupStr, setPopupStr] = useState('')

    const [chapati, setChapati] = useState(0)
    const [bhakri, setBhakri] = useState(0)

    const navigate = useNavigate()



    const closePopup = () => {
        setIsPopupOpen(false);
        navigate("/dashboard")

    };

    const clkHnd = (e) => {
        if (e.target.value === 'veg' || e.target.value === 'nonVeg')
            setMealType(e.target.value)
        //setVeg1([])
        //setVeg2([])

        setStep(step + 1)

        if (e.target.name === 'back' && step > 0) {
            setStep(step - 1)

        }

        if (e.target.name === 'next') {
            setStep(step + 1)

        }
    }

    const addHnd = (name, dish) => {
        if (name === 'veg1') {

            if (veg1.includes(dish)) {
                setVeg1(veg1.filter(veg => veg !== dish))
            } else {
                if (veg1.length < 2) {
                    setVeg1([...veg1, dish]);
                } else {
                    alert('Max 2 vegetables can be added');
                }
            }

        }

        if (name === 'nonVeg') {
            if (veg1.includes(dish)) {
                setVeg1([])
            } else {
                setVeg1([dish])
            }
        }


        if (name === 'bread') {
            if (breadType === dish) {
                setBreadType('')
            } else {
                setBreadType(dish)
            }

        }

        if (name === 'rice') {
            if (riceType === dish) {
                setRiceType('')
            } else {
                setRiceType(dish)
            }

        }

        if (name === 'sweet') {
            if (sweetType === dish) {
                setSweetType('')
            } else {
                setSweetType(dish)
            }

        }
    }

    useEffect(() => {
        const fetchMenu = async () => {
            try {
                const res = await axios.get("https://dlunchbox.com/menu")
                console.log(res.data)
                setData(res.data)
            } catch (err) {
                console.log(err)
            }



        }
        fetchMenu()
    }, [])

    const mahaVegetables = data?.filter(menu => menu.mealType === 'Veg' && menu.category === 'MaharashtrianVegetable' && menu.status === 1)
    const northVegetables = data?.filter(menu => menu.mealType === 'Veg' && menu.category === 'NorthIndianVegetable' && menu.status === 1)
    const bread = data?.filter(menu => menu.category === 'Bread' && menu.status === 1)
    const rice = data?.filter(menu => menu.category === 'Rice' && menu.mealType === 'Veg' && menu.status === 1)
    const rice2 = data?.filter(menu => menu.category === 'Rice' && menu.mealType === 'Nonveg' && menu.status === 1)
    const sweet = data?.filter(menu => menu.category === 'Sweet' && menu.status === 1)

    const nonVegMenu = data?.filter(menu => menu.mealType === 'Nonveg' && menu.category === 'Vegetable' && menu.status === 1)

    const sbtHnd = async () => {
        //console.log(mealType, veg1, sweetType, breadType, riceType, chapati, bhakri, " order")
        setPopupStr("Placing Order...Please Wait")
        setIsPopupOpen(true)
        const cid = Cookies.get('CID')
        try {
            const res = await axios.post("https://dlunchbox.com/SubmitOrder1", { cid, mealType, veg1, sweetType, breadType, riceType, chapati, bhakri })

            if (res.status === 200) {

                setPopupStr("Order Added To Cart Successfully...ThankYou")
            }

        } catch (err) {
            console.log(err)
        }

    }

    const extraHnd = (name, count) => {
        if (name === 'plus' && chapati < 6) {
            setChapati(chapati + count)

        } else if (name === 'minus' && chapati > 0) {
            setChapati(chapati - 1)
        } else if (name === 'plusB' && bhakri < 6) {
            setBhakri(bhakri + 1)
        } else if (name === 'minusB' && bhakri > 0) {
            setBhakri(bhakri - 1)
        }
    }

    return (
        <div className=''>
            <p className='text-3xl text-center font-bold mb-6 text-gray-800'>Customize your lunch according to your taste</p>
            <hr></hr>

            {step === 1 && <div className='flex  flex-col justify-center '>
                <div className='flex flex-row justify-center gap-4 lg:gap-8 mt-12 w-full'>
                    <button onClick={clkHnd} value='veg' className='bg-green-600 w-5/12 lg:w-2/12 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:-translate-y-1 hover:shadow-2xl'>Veg</button>
                    <button onClick={clkHnd} value='nonVeg' className='bg-red-600 w-5/12 lg:w-2/12 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition-transform transform hover:-translate-y-1 hover:shadow-2xl'>Non Veg</button>

                </div>
                <img src={order} className='lg:-mt-20 w-80'></img>
            </div>
            }

            {(step === 2 && mealType === 'veg') && <div>
                <div className='flex flex-row justify-center gap-12 lg:gap-80 mb-16 lg:mb-8'>
                    <p>Maharashtrian</p>
                    <p>North Indian</p>
                </div>

                <div className='flex flex-col lg:flex-row justify-center gap-20'>
                    <div className='w-full lg:w-5/12'>
                        {mahaVegetables?.map((menu, index) => {
                            return (
                                <div className='flex flex-row h-52 w-full lg:w-10/12 border border-solid border-gray-600 mb-4 rounded-lg'>
                                    <div className='h-full w-7/12'>
                                        <div className='h-full bg-cover bg-center' style={{ backgroundImage: `url(https://dlunchbox.com/Images/${menu.image})` }}>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-center items-center'>
                                        <div>
                                            <p className='text-center text-2xl font-semibold'>{menu.dishName}</p>
                                            <p className='text-sm text-center text-gray-500'>{menu.description}</p>
                                            <button onClick={() => { addHnd('veg1', menu.dishName) }} className='text-white bg-orange-500 hover:border border-black rounded-md mx-auto block mt-4 mb-4 w-5/12 h-10'>{veg1?.includes(menu.dishName) ? 'Remove' : 'Add'}</button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className='w-full lg:w-5/12'>
                        {northVegetables?.map((menu, index) => {
                            return (
                                <div className='flex flex-row h-52 w-full lg:w-10/12 border border-solid border-gray-600 mb-4 rounded-lg'>
                                    <div className='h-full w-7/12'>
                                        <div className='h-full bg-cover bg-center' style={{ backgroundImage: `url(https://dlunchbox.com/Images/${menu.image})` }}>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-center items-center'>
                                        <div>
                                            <p className='text-center text-2xl font-semibold'>{menu.dishName}</p>
                                            <p className='text-sm text-center text-gray-500'>{menu.description}</p>
                                            <button onClick={() => { addHnd('veg1', menu.dishName) }} className='text-white bg-orange-500 hover:border border-black rounded-md mx-auto block mt-4 mb-4 w-5/12 h-10'>{veg1?.includes(menu.dishName) ? 'Remove' : 'Add'}</button>
                                        </div>
                                    </div>
                                </div>


                            );
                        })}
                    </div>


                </div>


            </div>}

            {(step === 2 && mealType === 'nonVeg') && <div>
                <p className='text-center text-red-600 mb-4'>Note: Here only one dish is selectable</p>
                <div className='w-full flex flex-col lg:flex-row justify-center gap-8 flex-wrap'>

                    {nonVegMenu?.map((menu, index) => {
                        return <div className='flex flex-row h-52 w-full lg:w-5/12 border border-solid border-gray-600 mb-4 rounded-lg'>
                            <div className='h-full w-7/12'>
                                <div className='h-full bg-cover bg-center' style={{ backgroundImage: `url(https://dlunchbox.com/Images/${menu.image})` }}>
                                </div>
                            </div>
                            <div className='w-full flex justify-center items-center'>
                                <div>
                                    <p className='text-center text-2xl font-semibold'>{menu.dishName}</p>
                                    <p className='text-sm text-center text-gray-500'>{menu.description}</p>
                                    <button onClick={() => { addHnd('nonVeg', menu.dishName) }} className='text-white bg-orange-500 hover:border border-black rounded-md mx-auto block mt-4 mb-4 w-5/12 h-10'>{veg1?.includes(menu.dishName) ? 'Remove' : 'Add'}</button>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>}



            {
                step === 3 && <div>
                    <hr></hr>
                    <p className='text-center mt-4'>Select Bread Type</p>

                    <div className='flex flex-col lg:flex-row flex-wrap justify-center gap-4 mt-4'>
                        {bread.map((menu, index) => {
                            return <div className='flex flex-row h-52 w-full lg:w-5/12 border border-solid border-gray-600 mb-4 rounded-lg'>
                                <div className='h-full w-7/12'>
                                    <div className='h-full bg-cover bg-center' style={{ backgroundImage: `url(https://dlunchbox.com/Images/${menu.image})` }}>
                                    </div>
                                </div>
                                <div className='w-full flex justify-center items-center'>
                                    <div>
                                        <p className='text-center text-2xl font-semibold'>{menu.dishName}</p>
                                        <p className='text-sm text-center text-gray-500'>{menu.description}</p>
                                        <button onClick={() => { addHnd('bread', menu.dishName) }} className='text-white bg-orange-500 hover:border border-black rounded-md mx-auto block mt-4 mb-4 w-5/12 h-10'>{breadType?.includes(menu.dishName) ? 'Remove' : 'Add'}</button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            }

            {
                (step === 3 && mealType === 'veg') && <div>
                    <p className='text-center mt-4'>Select Rice Type</p>

                    <div className='flex flex-col lg:flex-row flex-wrap justify-center gap-4 mt-4'>
                        {rice.map((menu, index) => {
                            return <div className='flex flex-row h-52 w-full lg:w-5/12 border border-solid border-gray-600 mb-4 rounded-lg'>
                                <div className='h-full w-7/12'>
                                    <div className='h-full bg-cover bg-center' style={{ backgroundImage: `url(https://dlunchbox.com/Images/${menu.image})` }}>
                                    </div>
                                </div>
                                <div className='w-full flex justify-center items-center'>
                                    <div>
                                        <p className='text-center text-2xl font-semibold'>{menu.dishName}</p>
                                        <p className='text-sm text-center text-gray-500'>{menu.description}</p>
                                        <button onClick={() => { addHnd('rice', menu.dishName) }} className='text-white bg-orange-500 hover:border border-black rounded-md mx-auto block mt-4 mb-4 w-5/12 h-10'>{riceType?.includes(menu.dishName) ? 'Remove' : 'Add'}</button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            }

            {
                (step === 3 && mealType === 'nonVeg') && <div>
                    <p className='text-center mt-4'>Select Rice Type</p>

                    <div className='flex flex-col lg:flex-row flex-wrap justify-center gap-4 mt-4'>
                        {rice2.map((menu, index) => {
                            return <div className='flex flex-row h-52 w-full lg:w-5/12 border border-solid border-gray-600 mb-4 rounded-lg'>
                                <div className='h-full w-7/12'>
                                    <div className='h-full bg-cover bg-center' style={{ backgroundImage: `url(https://dlunchbox.com/Images/${menu.image})` }}>
                                    </div>
                                </div>
                                <div className='w-full flex justify-center items-center'>
                                    <div>
                                        <p className='text-center text-2xl font-semibold'>{menu.dishName}</p>
                                        <p className='text-sm text-center text-gray-500'>{menu.description}</p>
                                        <button onClick={() => { addHnd('rice', menu.dishName) }} className='text-white bg-orange-500 hover:border border-black rounded-md mx-auto block mt-4 mb-4 w-5/12 h-10'>{riceType?.includes(menu.dishName) ? 'Remove' : 'Add'}</button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            }

            {
                step === 3 && <div>
                    <p className='text-center mt-4'>Select Sweet Type</p>

                    <div className='flex flex-col lg:flex-row flex-wrap justify-center gap-4 mt-4'>
                        {sweet.map((menu, index) => {
                            return <div className='flex flex-row h-64 lg:h-52 w-full lg:w-5/12 border border-solid border-gray-600 mb-4 rounded-lg'>
                                <div className='h-full w-7/12'>
                                    <div className='h-full bg-cover bg-center' style={{ backgroundImage: `url(https://dlunchbox.com/Images/${menu.image})` }}>
                                    </div>
                                </div>
                                <div className='w-full flex justify-center items-center'>
                                    <div>
                                        <p className='text-center text-2xl font-semibold'>{menu.dishName}</p>
                                        <p className='text-sm text-center text-gray-500'>{menu.description}</p>
                                        <button onClick={() => { addHnd('sweet', menu.dishName) }} className='text-white bg-orange-500 hover:border border-black rounded-md mx-auto block mt-4 mb-4 w-5/12 h-10'>{sweetType?.includes(menu.dishName) ? 'Remove' : 'Add'}</button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            }

            {step == 4 && <div>
                <p className='text-center mb-8'>Would you like to add extra breads with this order ?. (Rs.10 extra per bread)</p>
                <Extra extraHnd={extraHnd} chapati={chapati} bhakri={bhakri} bread={bread} ></Extra>
            </div>}

            <div className=' flex flex-row justify-center gap-4 lg:gap-12 mx-auto left-0 right-0 mt-4 fixed bottom-10  z-index: 1000  w-8/12'>
                {step > 1 && <button onClick={clkHnd} name='back' className='bg-green-600  bg-opacity-70 hover:bg-opacity-100 py-1 px-12 lg:px-16 text-white  h-11 rounded-lg'>Back</button>}
                {(step > 1 && step < 4) && <button onClick={clkHnd} name='next' className='bg-green-600 bg-opacity-70 hover:bg-opacity-100  py-1 px-12 lg:px-16 text-white  h-11 rounded-lg'>Next</button>}
                {step === 4 && <button name='submit' onClick={sbtHnd} className='bg-red-600 text-white  px-12 lg:px-16 h-11 rounded-lg'>
                    Submit</button>}

            </div>

            {isPopupOpen && <SubmitPopup onClose={closePopup} str={popupStr}></SubmitPopup>}
        </div >
    )
}

export default NewFoodForm;
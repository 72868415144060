import React from 'react';
//import './Slippopus.css';
//import close from './close.png'
import './Signuppopup.css'
//import FoodForm from '../../foodForm.js/FoodForm';

const arr = ["BILLING DETAILS", "Packaging Cost", "basic 21.18", "CGST 9% 1.91", "SGST 9% 1.14", "Total 15.00", "Food Cost", "Basic 47.62", "CGST 2.5% 1.14", "SGST 2.5% 1.14", "Total 50", " Grand Total Of Lunch Box Rs.90"]
const Signuppopup2 = ({ onClose2, name, mob, address }) => {
    return (
        <div className="popup-container">

            <div className="popup newCss">
                <p className='text-center text-2xl text-green-800 font-medium'>Account Created Successfully</p>
                <p className='text-center mt-4'>Please login</p>
                <button className='mx-auto block bg-red-500 w-16 h-8 rounded-md text-white mt-8' onClick={onClose2}>OK</button>

                <div className='popBtnDiv'>




                </div>

            </div>


        </div>
    );
};

export default Signuppopup2;
import React from 'react'
import quote from '../asset/quote.png'
import star from '../asset/star.png'


const TestCard = (props) => {
    return (
        <div className='bg-white w-11/12 rounded-3xl py-8'>
            <img className='w-12 ml-8 mb-8' src={quote}></img>
            <p className='ml-8 pr-8'>{props.txt}</p>
            <img src={star} className='w-20 ml-8'></img>

            <div className='flex flex-row ml-8 gap-4'>
                <img src={props.image} className='rounded-full h-16 w-16 overflow-hidden'></img>
                <div className=''>
                    <p className='text-lg font-bold'>{props.name} </p>
                    <p>{props.city}</p>
                </div>
            </div>
        </div>
    )
}

export default TestCard;
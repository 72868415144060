import React from 'react';
import './NewPopup.css';
import close from './close.png'

import NewFoodForm from '../../foodForm/NewFoodForm';



const NewPopup = ({ onClose, closeMenu, txt3 }) => {
    return (
        <div className="popup-container ">

            <div className="Newpopup">
                <div className='flex justify-end'>
                    {txt3 !== "" && <button className='menuClose' onClick={onClose}>
                        <img src={close} className='w-4 mx-auto '></img>
                    </button>}
                </div>

                <NewFoodForm></NewFoodForm>





                <div className='popBtnDiv'>

                </div>

            </div>


        </div>
    );
};

export default NewPopup;
import React from 'react'
import './Category.css'

import customise from '../../Home/asset/customize.png'
import hygiene from '../../Home/asset/hygiene.png'
import menu from '../../Home/asset/menu.png'
import cooking from '../../Home/asset/cookingGreen.png'

//#219A00

const Category = () => {
    return (
        <div className='bg-green-100 py-8'>
            <p className='dancing text-center text-3xl text-green-800'>Our Benefits</p>
            <p className='text-green-900 text-center text-5xl font-semibold'>Why customers choose us</p>
            <p className='text-gray-500  text-center'>Our commitment to exceptional quality and unparalleled customer service sets us apart.</p>

            <div className='flex flex-col lg:flex-row justify-center mt-8 gap-12'>
                <div className='bg-white w-64 rounded-3xl mx-auto block'>
                    <img src={customise} className='w-16 mx-auto block mt-4'></img>
                    <p className='text-center my-4 text-2xl font-medium text-green-900'>Customised Lunch</p>
                    <p className='mx-4 mb-4 text-center'>We offer customized lunch boxes tailored to your preferences, ensuring your meals are both delicious and convenient.</p>
                </div>

                <div className='bg-white w-64 rounded-3xl mx-auto block'>
                    <img src={hygiene} className='w-16 mx-auto block mt-4'></img>
                    <p className='text-center my-4 text-2xl font-medium text-green-900'>Hygienically Packed</p>
                    <p className='mx-4 mb-4 text-center'>Hygienically packed lunches are essential for maintaining food safety and preventing contamination.</p>
                </div>

                <div className='bg-white w-64 rounded-3xl mx-auto block'>
                    <img src={menu} className='w-16 mx-auto block mt-4'></img>
                    <p className='text-center my-4 text-2xl font-medium text-green-900'>Mindful Menu </p>
                    <p className='mx-4 mb-4 text-center'>The lunch menu was crafted to promote health and wellness, offering a  array of nourishing dishes to inspire mindful eating habits</p>
                </div>

                <div className='bg-white w-64 rounded-3xl mx-auto block'>
                    <img src={cooking} className='w-16 mx-auto block mt-4'></img>
                    <p className='text-center my-4 text-2xl font-medium text-green-900'>Healthy Cooking </p>
                    <p className='mx-4 mb-4 text-center'>Healthy cooking techniques were employed to create a vibrant and nutritious menu, ensuring that every dish is not only delicious but also promotes overall well-being.</p>
                </div>

            </div>
        </div>
    )
}

export default Category;

































// import React, { useEffect, useState } from 'react'
// import axios from 'axios';
// import './Category.css';
// import FoodCard from '../foodCard/FoodCard';

// import Popup from '../popup/Popup';

// const dummy_data = [{
//     "type": "veg",
//     dish: "Rice Plate",
//     discription: "Our Veg rice plate is delicious and satisfying dish made with a patty of mixed vegetables, beans and spices. It's topped with tomato, onion, pickle etc",
//     ingredients: "rice,pickle, beans, peas",
//     price: "80"
// }, {}]

// const Category = () => {
//     const [veg, setVeg] = useState(true);
//     const [data, setData] = useState();
//     const [lunchCategory, setLunchCategory] = useState(false)

//     const [isPopupOpen, setIsPopupOpen] = useState(false);

//     const closePopup = () => {
//         setIsPopupOpen(false);

//     };

//     const vegMenuHnd = () => {
//         setVeg(true)
//     }

//     const nonVegMenuHnd = () => {
//         setVeg(false)
//     }

//     const lunchCategoryHnd = (status, boxType) => {
//         setIsPopupOpen(status)
//         setLunchCategory(boxType)
//     }

//     const getData = async () => {
//         try {
//             const response = await axios.get("http://localhost:8000/admin/getdishes",)
//             console.log(response.data)
//             setData(response.data)
//         } catch (err) {
//             console.log(err)
//         }
//     }

//     useEffect(() => {
//         getData();
//     }, [])

//     return (
//         <div className='py-8'>
//             <p className='text text-center text-xl font-semibold text-600'>Special Menu</p>


//             <div className='mt-4'>
//                 <p className='text-center text-red-600  text-4xl font-semibold'>Signature Dish</p>

//                 <div className='flex flex-row justify-center mt-8'>
//                     <button onClick={vegMenuHnd} className='bg-green-600 mr-4 w-32 h-12 rounded text-white'>Veg</button>
//                     <button onClick={nonVegMenuHnd} className='bg-red-600 w-32 h-12 rounded text-white'>Non Veg</button>
//                 </div>

//             </div>

//             {/* food cards */}
//             <div className='flex flex-wrap flex-row justify-center gap-8 my-8'>
//                 {data && data.map((dish, id) => (
//                     <FoodCard
//                         key={id}
//                         image={dish.image}
//                         type={dish.type}
//                         dish={dish.name}
//                         discription={dish.discription}
//                         price={dish.price}
//                         id={dish.id}
//                         lunchCategoryHnd={lunchCategoryHnd}
//                     />
//                 ))}


//             </div>
//             {isPopupOpen && <Popup onClose={closePopup} txt1='Customise your lunch box' txt2={lunchCategory} txt3='.' />}
//         </div >

//     )
// }

// export default Category;
import React, { useEffect } from 'react';
import './CanclePolicy.css';


const CanclePolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="terms-and-conditions">
            <br></br><br></br><br></br><br></br><br></br>
            <h1>DlunchBox No Food Return and Cancellation Policy</h1>
            <h2>A. No Food Return Policy</h2>
            <p>At DlunchBox, we strive to deliver food that meets our high standards of quality and hygiene. Due to the nature of our products, we cannot accept returns once an order has been prepared and/or delivered. This policy is in place to ensure the safety and health of our customers, as food items cannot be reused or resold.</p>
            <h2>B. Exceptions may be made in the following circumstances</h2>
            <p>B.1: If the item delivered is different from what was ordered.</p>
            <p>B.2: If the food delivered is in a damaged condition or has been compromised in terms of quality and hygiene, evidenced by photos and a formal complaint within 2 hours of delivery.</p>
            <p>In such cases, please contact our customer service team immediately, and we will arrange a replacement or refund accordingly.</p>
            <h2>C. Cancellation Policy</h2>
            <p>We understand that plans can change, and we offer the following options for order cancellation</p>
            <p>C.1: Full Refund: Cancellations made within 5 minutes of placing an order will be eligible for a full refund.</p>
            <p>C.2: Partial Refund: Cancellations made up to 30 minutes before the scheduled delivery time will be eligible for a 50% refund.</p>
            <p>c.3: No Refund: Cancellations made less than 30 minutes before the scheduled delivery time will not be eligible for a refund, as the order would have been prepared and is likely en route to its destination.</p>
            <h2>D. Contact Us</h2>
            <p>For any questions or concerns regarding your order, please reach out to us via our customer service chat on the DlunchBox app or at support@dlunchbox.com. Our team is available to assist you from 8 AM to 10 PM every day.</p>
            <p>Nirvana Club, At Viva Inn Building, Dr. Homi Bhabha Rd., Bavdhan Chandini, Chawk, Pune 411021</p>
        </div>
    );
};

export default CanclePolicy;

import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "./Nav.css";
import cart from '../Home/asset/cart.png';
import logo from './logo.png';
import Cookies from 'js-cookie';
import axios from 'axios';
axios.defaults.withCredentials = true;

function Nav() {
    const navigate = useNavigate();
    const [click, setClick] = useState(false);
    const [cookieValue, setCookieValue] = useState(null);

    const loginSuccess = Cookies.get('CID');
    const loginName = Cookies.get('NAME');
    const adminSuccess = Cookies.get("ADMINSUCCESS");

    const logBtnHnd = async () => {
        if (loginSuccess) {
            Cookies.remove('CID');
            Cookies.remove('NAME');
            Cookies.remove("ADMINSUCCESS");
            navigate('/');
            window.location.reload();
        } else {
            navigate('/login');
        }
        setClick(false); // Close navbar on click
    };

    const handleClick = () => setClick(!click);

    const cartHnd = () => {
        navigate("/dashboard");
        setClick(false); // Close navbar on click
    };

    useEffect(() => {
        const custname = Cookies.get('custname');
        if (custname) {
            setCookieValue(custname);
        }
    }, []);

    return (
        <>
            <header>
                <nav className="navbar">
                    <div className="nav-container">
                        <NavLink exact to="/" className="nav-logo">
                            <img src={logo} alt="securise"></img>
                        </NavLink>

                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/about"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/career"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Career
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    exact
                                    to="/contact"
                                    activeClassName="active"
                                    className="nav-links"
                                    onClick={handleClick}
                                >
                                    Contact Us
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                {loginSuccess && <div className="dropdown">
                                    <p className="dropdownContent">Hello {loginName} ⮞</p>
                                    <div className="dropdown-content">
                                        {adminSuccess !== 'true' && <Link to='/dashboard'>My Dashboard</Link>}
                                        <button className="login2" onClick={logBtnHnd} >{loginSuccess ? 'Logout' : 'Login'}</button>
                                    </div>
                                </div>}
                                {!loginSuccess && <button className="login" onClick={logBtnHnd} >{loginSuccess ? 'Logout' : 'Login'}</button>}
                            </li>
                            <li>
                                <button onClick={cartHnd} className="flex flex-row w-12 text text-white mt-3">
                                    <img src={cart} alt="cart"></img>
                                </button>
                            </li>
                        </ul>
                        <div className="nav-icon" onClick={handleClick}>
                            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Nav;

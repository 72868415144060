import React from 'react';
//import './Slippopus.css';
//import close from './close.png'

//import FoodForm from '../../foodForm.js/FoodForm';

const arr = ["BILLING DETAILS", "Packaging Cost", "basic  Rs.21.18", "CGST 9% 1.91", "SGST 9% 1.14", "Total 15.00", "Food Cost", "Basic 47.62", "CGST 2.5% 1.14", "SGST 2.5% 1.14", "Total 50", " Grand Total Of Lunch Box Rs.90"]
const Slippopup = ({ onClose, name, mob, address, landmark, pincode, city }) => {
    return (
        <div className="popup-container">

            <div className="popup">
                <div className='flex float-end'>
                    <button className='popBtn2' onClick={onClose}>
                        <img src='' className='w-4 mx-auto '></img>
                    </button>
                </div>

                <div className='flex flex-row justify-center gap-20 mt-12'>
                    <p className=''><b>Name: </b><span>{name}</span></p>
                    <p className=''><b>Mobile: </b><span>{mob}</span></p>
                </div>
                <p className='text-center'><b>Address: </b><span>{address}</span></p>

                <div className='flex flex-row justify-center gap-4 mb-4'>
                    <p><b>landmark: </b><span>{landmark}</span></p>
                    <p><b>pincode:</b> <span>{pincode}</span></p>
                    <p><b>city: </b><span>{city}</span></p>
                </div>

                <div>
                    {arr.map((item) => { return <p>{item}</p> })}

                </div>




                <div className='popBtnDiv'>




                </div>

            </div>


        </div>
    );
};

export default Slippopup;
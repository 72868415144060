import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom"
import CartSuccessPopup from '../Home/popup/CartSuccessPopup';
import "./FF.css"
axios.defaults.withCredentials = true;

const FoodForm = ({ lunch, onClose }) => {
    const [selected, setSelected] = useState([]);
    const [count, setCount] = useState(0);
    const [mealType, setMealType] = useState();
    const [cuisineType, setCuisineType] = useState('');
    const [breadType, setBreadType] = useState('');
    const [riceType, setRiceType] = useState('');
    const [sweetOption, setSweetOption] = useState('');

    //state for popup add to card successfull
    const [showPop, setShowPop] = useState(false)

    const navigate = useNavigate();

    const handleClosePopup = () => {
        setShowPop(false)
        navigate("/dashboard")
    }





    const chkHnd = (e) => {
        if (selected.length <= 1) {
            if (e.target.checked) {
                if (!selected.includes(e.target.value)) {
                    setSelected([...selected, e.target.value])
                }
            }
        }
        if (!e.target.checked) {
            setSelected(selected.filter(item => item !== e.target.value))
        }
    }



    const handleBreadTypeChange = (e) => {
        setBreadType(e.target.value);
    }

    const handleRiceTypeChange = (e) => {
        setRiceType(e.target.value);
    }

    const handleSweetOptionChange = (e) => {
        setSweetOption(e.target.value);
    }

    const handleMealTypeChange = (e) => {
        setMealType(e.target.value);
        setCuisineType(''); // Reset cuisine type when meal type changes
    }

    const handleCuisineTypeChange = (e) => {
        const { value } = e.target;
        if (e.target.checked) {
            if (mealType === "veg" && cuisineType.length < 2) {
                // If meal type is vegetarian and less than 2 dishes are selected, add the dish to the cuisineType array
                setCuisineType([...cuisineType, value]);
            } else if (mealType === "nonVeg" && cuisineType.length === 0) {
                // If meal type is non-vegetarian and no dish is selected, add the dish to the cuisineType array
                setCuisineType([value]);
            } else {
                // Prevent further selection if the maximum number of dishes is reached
                e.target.checked = false;
            }
        } else {
            // Remove the unchecked dish from the cuisineType array
            setCuisineType(cuisineType.filter(item => item !== value));
        }
    }

    const nextHnd = () => {
        setCount(prevCount => prevCount + 1)
    }

    const backHnd = () => {
        setCount(prevCount => prevCount - 1)
    }




    // State for user data and updated data
    const [userData, setUserData] = useState({});
    const [updatedData, setUpdatedData] = useState({});






    useEffect(() => {
        const fetchUserAddress = async () => {
            try {

                const response = await axios.get('https://dlunchbox.com/gcaddr');
                setUserData(response.data);
                setUpdatedData(response.data);
                console.log(userData)
            } catch (error) {
                console.error('Error fetching user profile:', error);

            }
        };

        fetchUserAddress();
    }, []);


    const handleUpdate = async (e) => {
        e.preventDefault();
        try {

            const response = await axios.put('https://dlunchbox.com/updatecustadd', updatedData);
            if (response.data !== 'error') {
                console.log(response.data);
                next();
                alert('Your Order Has Been Placed!');
                onClose();
                setUserData(updatedData);
            }
        } catch (error) {
            console.error('Error updating Address:', error);
            alert('Address Not Updated! Please Try Again');
        }
    };

    // Function to handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUpdatedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };






    const next = () => {

        const cpk = Cookies.get('custPrimaryKey');
        const id = uuidv4();
        let cuisineType = [mealType];
        let flattenedCuisineType = cuisineType.flat();
        let vegetable1 = flattenedCuisineType[0];
        let vegetable2 = flattenedCuisineType.length === 2 ? flattenedCuisineType[1] : null;
        console.log(id, vegetable1, vegetable2, breadType, riceType, sweetOption);


        const orderDetails = {
            id: id,
            cid: cpk,
            vegetable1: vegetable1,
            vegetable2: vegetable2,
            bread: breadType,
            rice: riceType,
            sweet: sweetOption
        };





        const Submit_Order = async () => {
            try {
                const response = await axios.post('https://dlunchbox.com/ord', orderDetails);
                if (response.data != 'error') {
                    console.log(response.data);
                    alert('Order Placed successfully!');

                }

            } catch (error) {
                console.error('Error While order Placing:', error);
                alert('Order Not Updated! Please Try Again');
            }
        };



    }


    const submitHandle = async (e) => {
        e.preventDefault();

        const cid = Cookies.get('CID')

        const CUST_DISH = {
            id: uuidv4(),
            cid: cid,
            mealtype: mealType,
            vegetable1: cuisineType[0],
            vegetable2: cuisineType[1],
            bread: breadType,
            rice: riceType,
            sweet: sweetOption
        }
        console.log(CUST_DISH, "PP")
        try {
            const res = await axios.post("https://dlunchbox.com/SubmitOrder1", { CUST_DISH })
            console.log(res, "LLOP")
            setShowPop(true)
        } catch (err) {
            console.log(err)
        }

    }

    return (
        <div>

            <p className='text-2xl'>{lunch}</p>
            <div className='jkd'>
                <form >
                    {count === 0 &&
                        <div>
                            <p className='text-red-800'>Customize your lunch according to your taste</p>
                            <hr />
                            <br /><br />
                            <p>Select meal type:</p>
                            <br />
                            <div className="flex flex-row justify-around gap-4">
                                <label className='text-red-800'  >
                                    <input type="radio" name="mealType" value="veg" checked={mealType === "veg"} onChange={handleMealTypeChange} className="Veg" />

                                </label>
                                <label className='text-red-800'  >
                                    <input type="radio" name="mealType" value="nonVeg" checked={mealType === "nonVeg"} onChange={handleMealTypeChange} className="Nonveg" />

                                </label>
                            </div>
                        </div>
                    }

                    {count === 1 &&
                        <div className='flex flex-row flex-wrap justify-center gap-12 text-red'>
                            <ul>
                                {mealType === "veg" &&
                                    <React.Fragment>
                                        <div className='flex flex-col lg:flex-row justify-left align-left gap-10'>
                                            <div>
                                                <h2 className="text-red-800 text-lg">Maharashtrian</h2>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='vangiMasala' checked={cuisineType.includes("vangiMasala")} onChange={handleCuisineTypeChange} className="Vangimasala" />
                                                        <br /><p className='de'>  Description: Bengan along with peanuts, onion, garlic, coriender and our special gravy. </p><br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='matarMasala' checked={cuisineType.includes("matarMasala")} onChange={handleCuisineTypeChange} className="Matarmasala " />
                                                        <br /> <p className='de'> Description: Green piece along with special gravy, onion, tomato, green chilli, shimla mirchi, indian spicy masala, Brown sauce </p><br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='shevBhaji' checked={cuisineType.includes("shevBhaji")} onChange={handleCuisineTypeChange} className="SHEVBHAJI" />
                                                        <br /> <p className='de'>Description: Shev, tomato, onion, alogn with grlic , ginger, green chilli and our special gravy, indian spicy masala </p>   <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='bhendiFry' checked={cuisineType.includes("bhendiFry")} onChange={handleCuisineTypeChange} className="Bhendifry" />
                                                        <br /> <p className='de'>Description: Bhendi alogn with chilli powder, turmeric powder, curd, besan, coriender powder </p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='bharliVangi' checked={cuisineType.includes("bharliVangi")} onChange={handleCuisineTypeChange} className="BharaliVangi" />
                                                        <br /> <p className='de'> Description:  Bengan along with garlic, ginger, onion tomato, indian spicy masala and our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                {/* <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='rajmaMasala' checked={cuisineType.includes("rajmaMasala")} onChange={handleCuisineTypeChange} className="Rajmamasala" />
                                                        <br /> <p className='de'> Description:  Rajma along with garlic, ginger, onion, tomato, indian spicy masaoa & our special gravy</p> <br />
                                                    </label>
                                                </div> */}
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='pithla' checked={cuisineType.includes("pithla")} onChange={handleCuisineTypeChange} className="Pithala" />
                                                        <br /> <p className='de'> Description: Besan along with Garlic, Ginger onion </p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='blackChana' checked={cuisineType.includes("blackChana")} onChange={handleCuisineTypeChange} className="blackchana" />
                                                        <br /> <p className='de'> Description:  Black Chana along with garlic, ginger onion tomato,, indian spicy masala & our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='chavaliMasala' checked={cuisineType.includes("chavaliMasala")} onChange={handleCuisineTypeChange} className="chavalimasala" />
                                                        <br /> <p className='de'> Description:  Chavali along wth onion, tomato, green chilli, peanuts, indian spicy manala and our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='matakiMasala' checked={cuisineType.includes("matakiMasala")} onChange={handleCuisineTypeChange} className="matakimasala" />
                                                        <br /> <p className='de'> Description:  Mataki Along with Garlic ginger, onion, tomato, Red Chilli , Coriender powder, indian garam masala</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='dalFry' checked={cuisineType.includes("dalFry")} onChange={handleCuisineTypeChange} className="Dalfry" />
                                                        <br /> <p className='de'> Description:  TurDal , Mungadal, Masur dal along with ginger, garlic, onion, tomato, green chillim turmeric powder, jeera, red chilli, and our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div></div>
                                                {/* Add more checkboxes for other options */}
                                            </div>
                                            <div>
                                                <h2 className="text-red-800 text-lg">North Indian</h2>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='matarPaneer' checked={cuisineType.includes("matarPaneer")} onChange={handleCuisineTypeChange} className="MATARPANEER" />
                                                        <br /> <p className='de'> Description:  Green Piece, Paneer along with ginger, garlic, onion, indian spicy masala and our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='paneerDoPyaja' checked={cuisineType.includes("paneerDoPyaja")} onChange={handleCuisineTypeChange} className="PDOPY" />
                                                        <br /> <p className='de'> Description:  Paneer along with onion, tomaoto, capcicum, Red chilli, coriender, cheese, and cream</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='kadhaiPaneer' checked={cuisineType.includes("kadhaiPaneer")} onChange={handleCuisineTypeChange} className="KP" />
                                                        <br /> <p className='de'> Description:  Paneer along with onion, ginger, kaju, indian spicy masala and our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='palakPaneer' checked={cuisineType.includes("palakPaneer")} onChange={handleCuisineTypeChange} className="Palakpaneer" />
                                                        <br /> <p className='de'> Description:  Palak, Paneer along with onion, tomato, green chilli, garlic, gingr, inian spicy masala and ou r special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='alooJeera' checked={cuisineType.includes("alooJeera")} onChange={handleCuisineTypeChange} className="Aloojeera" />
                                                        <br /> <p className='de'> Description:  Potato, jeera along wit turmeric powder, salt and pater to taste</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='alooMuttar' checked={cuisineType.includes("alooMuttar")} onChange={handleCuisineTypeChange} className="aloomuttar" />
                                                        <br /> <p className='de'> Description: Potato Green piece along with garlic, ginger, green chilli, tomato , onion, and our special gravy </p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='alooMuttarTamato' checked={cuisineType.includes("alooMuttarTamato")} onChange={handleCuisineTypeChange} className="almut" />
                                                        <br /> <p className='de'> Description:  Potatao, Green piece along with garlic, ginger, green chilli, tomato onion, our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='chanaMasala' checked={cuisineType.includes("chanaMasala")} onChange={handleCuisineTypeChange} className="chanamasala" />
                                                        <br /> <p className='de'> Description:  Chana, garlic, ginger, green chili, tomato onion and our special gravy</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='bhindiMasala' checked={cuisineType.includes("bhindiMasala")} onChange={handleCuisineTypeChange} className="bhindimasala" />
                                                        <br /> <p className='de'> Description:  Bhindi , green chilli, ginger, jeer,, salt and paper and our special gravity</p> <br />
                                                    </label>
                                                </div>
                                                <div className="dekr">
                                                    <label className='text-red-800 flex flex-col'>
                                                        <input type='checkbox' name='cuisineType' value='mixVeg' checked={cuisineType.includes("mixVeg")} onChange={handleCuisineTypeChange} className="mixveg" />
                                                        <br /> <p className='de'> Description:  Seasonal Vegetables which avialble as per season</p> <br />
                                                    </label>
                                                </div>

                                                {/* Add more checkboxes for other options */}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                                {mealType === "nonVeg" &&
                                    <React.Fragment>
                                        <div className="dekr">
                                            <label className='text-red-800 flex flex-col'>
                                                <input type='checkbox' name='cuisineType' value='chickenMasala' checked={cuisineType.includes("chickenMasala")} onChange={handleCuisineTypeChange} className="chim"></input>
                                                <br /> <p className='de'> Description: Chicken along with onion, garlic, ginger, kaju, indian spicy masala, and our special gravy </p> <br />
                                            </label>
                                        </div>
                                        <div className="dekr">
                                            <label className='text-red-800 flex flex-col'>
                                                <input type='checkbox' name='cuisineType' value='chickenSukkha' checked={cuisineType.includes("chickenSukkha")} onChange={handleCuisineTypeChange} className="chisu"></input>
                                                <br /> <p className='de'> Description:  Chicken along with onion. Green chillin, tomato, turmeric powder, coriender kadipatta, coconut powder</p> <br />
                                            </label>
                                        </div>
                                        <div className="dekr">
                                            <label className='text-red-800 flex flex-col'>

                                                <input type='checkbox' name='cuisineType' value='chickengharwala' checked={cuisineType.includes("chickengharwala")} onChange={handleCuisineTypeChange} className="chigh"></input>
                                                <br /> <p className='de'> Description:  Chicken along with tomato, green chilli, onion, ginger, garlic, Indian spicy masala and our special gravy</p> <br />
                                            </label>
                                        </div>
                                        <div className="dekr">
                                            <label className='text-red-800 flex flex-col'>
                                                <input type='checkbox' name='cuisineType' value='eggmasala' checked={cuisineType.includes("eggmasala")} onChange={handleCuisineTypeChange} className="egm"></input>
                                                <br /> <p className='de'> Description:  Egg Along with onion Garlic, Ginger, Kaju, indian spicy masala, and our special gravy</p> <br />
                                            </label>
                                        </div>
                                    </React.Fragment>
                                }
                            </ul>
                        </div>
                    }




                    {count === 2 && (
                        <div>
                            <ul>
                                {mealType === "veg" && <React.Fragment>
                                    <div>
                                        <div>
                                            <br />
                                            <p className='text-red-800'>Select Bread Type:</p><br />
                                            <div className="flex justify-center flex-wrap gap-4">
                                                <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="breadType" value="roti" checked={breadType === "roti"} onChange={handleBreadTypeChange} className="Roti" />
                                                </label>
                                                <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="breadType" value="poli" checked={breadType === "poli"} onChange={handleBreadTypeChange} className="Chapati" />
                                                </label>
                                                <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="breadType" value="bhakari" checked={breadType === "bhakari"} onChange={handleBreadTypeChange} className="Bhakari" />
                                                </label>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <p className='text-red-800'>Select Rice Type:</p><br />
                                            <div className="flex justify-center flex-wrap gap-4">
                                                <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="riceType" value="dalBhat" checked={riceType === "dalBhat"} onChange={handleRiceTypeChange} className="dalbhat" />
                                                </label>
                                                <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="riceType" value="pulav" checked={riceType === "pulav"} onChange={handleRiceTypeChange} className="pulav" />
                                                </label>
                                            </div>
                                        </div>

                                        <div>
                                            <br />
                                            <p className='text-red-800'>Select Sweet Option:</p><br />
                                            <div className="flex justify-center flex-wrap gap-4">
                                                <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="sweetOption" value="gulabjam" checked={sweetOption === "gulabjam"} onChange={handleSweetOptionChange} className="gulabjam" />
                                                </label>
                                                <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="sweetOption" value="kheer" checked={sweetOption === "kheer"} onChange={handleSweetOptionChange} className="kheer" />
                                                </label>
                                                {/* <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="sweetOption" value="pineappleShiira" checked={sweetOption === "pineappleShiira"} onChange={handleSweetOptionChange} className="psh" />
                                                </label> */}
                                                {/* <label className='text-red-800 flex items-center'>
                                                    <input type="checkbox" name="sweetOption" value="shira" checked={sweetOption === "shira"} onChange={handleSweetOptionChange} className="shira" />
                                                </label> */}
                                            </div>
                                        </div>
                                        <br />

                                    </div>
                                </React.Fragment>
                                }

                                {mealType === "nonVeg" &&
                                    <React.Fragment>
                                        <div>
                                            <div>
                                                <br />
                                                <p className='text-red-800'>Select Bread Type:</p><br />
                                                <div className="flex justify-center flex-wrap gap-4">
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="breadType" value="roti" checked={breadType === "roti"} onChange={handleBreadTypeChange} className="Roti" />
                                                    </label>
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="breadType" value="poli" checked={breadType === "poli"} onChange={handleBreadTypeChange} className="Chapati" />
                                                    </label>
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="breadType" value="bhakari" checked={breadType === "bhakari"} onChange={handleBreadTypeChange} className="Bhakari" />
                                                    </label>
                                                </div>
                                            </div>

                                            <div>
                                                <br />
                                                <p className='text-red-800'>Select Rice Type:</p><br />
                                                <div className="flex justify-center flex-wrap gap-4">
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="riceType" value="rice&gravy" checked={riceType === "rice&gravy"} onChange={handleRiceTypeChange} className="ricegravy" />
                                                    </label>
                                                </div>
                                            </div>

                                            <div>
                                                <br />
                                                <p className='text-red-800'>Select Sweet Option:</p><br />
                                                <div className="flex justify-center flex-wrap gap-4">
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="sweetOption" value="gulabjam" checked={sweetOption === "gulabjam"} onChange={handleSweetOptionChange} className="gulabjam" />
                                                    </label>
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="sweetOption" value="kheer" checked={sweetOption === "kheer"} onChange={handleSweetOptionChange} className="kheer" />
                                                    </label>
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="sweetOption" value="pineappleShiira" checked={sweetOption === "pineappleShiira"} onChange={handleSweetOptionChange} className="psh" />
                                                    </label>
                                                    <label className='text-red-800 flex items-center'>
                                                        <input type="checkbox" name="sweetOption" value="shira" checked={sweetOption === "shira"} onChange={handleSweetOptionChange} className="shira" />
                                                    </label>
                                                </div>
                                            </div>
                                            <br />

                                        </div>
                                    </React.Fragment>}
                            </ul>
                        </div>
                    )}



                </form>
            </div>
            <div className="fsbcb">

                {count === 0 && count < 2 ? (
                    <button onClick={nextHnd} className='bg-green-300 w-20 h-8 gap-4'>Next</button>
                ) : (
                    <>
                        <button onClick={backHnd} className='bg-green-300 w-20 h-8  gap-4 dse'>Back</button>
                        {count < 2 ? (
                            <button onClick={nextHnd} className='bg-green-300 w-20  gap-4 h-8'>Next</button>
                        ) : (
                            <>

                                <button onClick={submitHandle} className='bg-green-300 w-20 h-8'>Submit</button>
                            </>
                        )}
                    </>
                )}

            </div>



            {/* {count === 3 && (
            <div className="w-full h-full fixed top-0 left-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="bg-white rounded-lg p-8">
        <h1 className="text-2xl font-bold mb-4">Check Your Address Once...</h1>
        <form onSubmit={handleUpdate}>
            <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col">
                    <label htmlFor="al1" className="text-red-800">Address Line 1</label>
                    <input
                        className="border rounded p-2 text-red-800"
                        type="text"
                        placeholder="Enter Building name, Survey no, Flat no"
                        id="al1"
                        name="al1"
                        defaultValue={userData.al1}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="al2" className="text-red-800">Address Line 2</label>
                    <input
                        className="border rounded p-2 text-red-800"
                        type="text"
                        placeholder="Lane no, Road name, Landmark"
                        id="al2"
                        name="al2"
                       defaultValue={userData.al2}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="vt" className="text-red-800">Village / Town / City</label>
                    <input
                        className="border rounded p-2 text-red-800"
                        type="text"
                        placeholder="Village / Town / City"
                        id="vt"
                        name="vt"
                        defaultValue={userData.vt}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="state" className="text-red-800">State</label>
                    <input
                        className="border rounded p-2 text-red-800"
                        type="text"
                        placeholder="State"
                        id="state"
                        name="state"
                        defaultValue={userData.state}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="country" className="text-red-800">Country</label>
                    <input
                        className="border rounded p-2 text-red-800"
                        type="text"
                        placeholder="Country"
                        id="country"
                        name="country"
                        defaultValue={userData.country}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="pin" className="text-red-800 text-red-800">Pincode</label>
                    <input
                        className="border rounded p-2 text-red-800"
                        type="text"
                        placeholder="Enter Your Pincode"
                        id="pin"
                        name="pin"
                        minLength={6}
                        maxLength={6}
                        pattern="[0-9]*"
                        defaultValue={userData.pin}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="amno" className="text-red-800">Alternate Mobile Number</label>
                    <input
                        className="border rounded p-2  text-red-800"
                        type="text"
                        placeholder="Eg. 9112233177 (10 DIGITS ALLOWED)"
                        id="amno"
                        name="amno"
                        minLength={10}
                        maxLength={10}
                        pattern="[0-9]*"
                        defaultValue={userData.amno}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="flex justify-center mt-4">
                <button type="submit"   className="bg-green-500 text-white py-2 px-4 rounded-md mr-2">Submit</button>
               
            </div>
        </form>
    </div>
</div>

)}




{/*}

                    {count === 2 && (
                        <div>

                            <div><br />
                                <p className='text-red-800'>Select Bread Type:</p><br />
                                <div className="flex flex-wrap gap-4">
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="breadType" value="roti" checked={breadType === "roti"} onChange={handleBreadTypeChange} className="Roti" />

                                    </label>
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="breadType" value="poli" checked={breadType === "poli"} onChange={handleBreadTypeChange} className="Chapati" />

                                    </label>
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="breadType" value="bhakari" checked={breadType === "bhakari"} onChange={handleBreadTypeChange} className="Bhakari" />

                                    </label>
                                </div>
                            </div>

                            <div><br />
                                <p className='text-red-800'>Select Rice Type:</p><br />
                                <div className="flex flex-wrap gap-4">
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="riceType" value="dalBhat" checked={riceType === "dalBhat"} onChange={handleRiceTypeChange} className="dalbhat" />

                                    </label>

                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="riceType" value="pulav" checked={riceType === "pulav"} onChange={handleRiceTypeChange} className="pulav" />

                                    </label>

                                </div>
                            </div>

                            <div><br />
                                <p className='text-red-800'>Select Sweet Option:</p><br />
                                <div className="flex flex-wrap gap-4">
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="sweetOption" value="gulabjam" checked={sweetOption === "gulabjam"} onChange={handleSweetOptionChange} className="gulabjam" />

                                    </label>
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="sweetOption" value="kheer" checked={sweetOption === "kheer"} onChange={handleSweetOptionChange} className="kheer" />

                                    </label>
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="sweetOption" value="pineappleShiira" checked={sweetOption === "pineappleShiira"} onChange={handleSweetOptionChange} className="psh" />

                                    </label>
                                    <label className='text-red-800 flex items-center'>
                                        <input type="checkbox" name="sweetOption" value="shira" checked={sweetOption === "shira"} onChange={handleSweetOptionChange} className="shira" />

                                    </label>
                                </div>
                            </div>
                            <br />
                            {/*} <div>
                                <button type="submit" onClick={submitHandle} className="bg-green-500 text-white py-2 px-4 rounded-md mx-auto block mt-4 w-60">Submit</button>
                            </div>* /}


                        </div>
                    )}












            {count === 0 && count < 2 ? (
                <button onClick={nextHnd} className='bg-green-300 w-20 h-8 '>Next</button>
            ) : (
                <>
                    <button onClick={backHnd} className='bg-green-300 w-20 h-8'>Back</button>
                    {count < 2 ? (
                        <button onClick={nextHnd} className='bg-green-300 w-20 h-8'>Next</button>
                    ) : (
                        ""
                    )}
                </>
            )}









*/}















            {showPop && (
                <CartSuccessPopup
                    message="Your LunchBox added to Cart successfully!"
                    onClose={handleClosePopup}
                />
            )}

        </div>
    )
}

export default FoodForm;
import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import classes from './Login.module.css';
import Cookies from 'js-cookie';
import i from './asset/img4.jpg';

axios.defaults.withCredentials = true;

const Login = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["login"]);
    const [data, setData] = useState({
        mobile: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleCheckbox = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post("https://dlunchbox.com/login", data);
            console.log(res.data.data[0].name);
            Cookies.set('NAME', res.data.data[0].name, { expires: 1 / 24, secure: true });
            Cookies.set("CID", res.data.data[0].cid, { expires: 1 / 24, secure: true });
            Cookies.set("ADMINSUCCESS", res.data.Admin, { expires: 1 / 24, secure: true });
            localStorage.setItem('token', res.data.token);
            navigate("/");
            window.location.reload();
        } catch (error) {
            if (error.response) {
                alert(error.response.data);
            } else {
                alert('An error occurred while logging in.');
            }
        }
    };

    return (
        <div>
            <p className="mt-28 mb-12 text-5xl font-bold text-center multicolor">Login</p>
            <div className={classes.container}>
                <div className={classes.imageWrapper}>
                    <img src={i} alt="Image" className={classes.image} />
                </div>
                <div className={classes.formWrapper}>
                    <form onSubmit={handleSubmit}>
                        <input name='mobile' onChange={handleChange} type='number' placeholder='Mobile number' className={classes.input} /><br />
                        <input name='password' onChange={handleChange} type={showPassword ? 'text' : 'password'} placeholder='Password' className={classes.input} />
                        <div className={classes.checkboxWrapper}>
                            <input type="checkbox" checked={showPassword} onChange={handleCheckbox} />
                            <label>Show Password</label>
                        </div>
                        <br />
                        <button className={classes.button} type="submit">Submit</button>
                        <p className={classes.signupText}>Don't have an account? <Link to='/signup'><span className={classes.signupLink}>Register</span></Link></p>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;

import React from 'react';
import './MenuDeletePop.css';

const MenuDeletePop = ({ onClose, deleteId, txt1, txt2, txt3, AdminDel, dishDeleteHnd }) => {
    return (
        <div className="delpopup-container">
            <div className="delpopup">
                <h2>{txt1}</h2>
                <p className='text-center text-2xl font-semibold'>Delete Dish ?</p>

                <div className='popBtnDiv'>
                    <buttton onClick={() => { dishDeleteHnd(deleteId) }} className='bg-red-600 delpopBtn text-white py-2 px-8 rounded'>Yes</buttton>
                    <buttton onClick={onClose} className='bg-green-500 py-2 delpopBtn px-8 rounded'>No</buttton>

                </div>

            </div>


        </div>
    );
};

export default MenuDeletePop;
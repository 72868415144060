import React from 'react'
import WhyCard from './WhyCard'
import './Whyus.css'

import free from '../Home/asset/free.png'
import cardImg1 from './asset/whyCard1.png'
import cardImg2 from './asset/whyCard2.png'
import cardImg3 from './asset/whyCard3.png'
import cardImg4 from './asset/whyCard4.png'

import dish from './asset/dish.png'
import thumb from './asset/thumb-up.png'
import cooking from './asset/cooking.png'

//#FF7C00
//#219A00 green icon

const Whyus = () => {
    return (
        <div className='mb-28'>
            <div className='mt-28 mb-12 flex flex-col lg:flex-row justify-center gap-28'>
                <div className='mt-12 '>
                    <p className='text-4xl font-semibold sm:text-center'>Real Delicious Food</p>
                    <p className='text-4xl font-semibold'>Straight To Your Door</p>

                    <p className='text-gray-500 mt-4'>Elevate Your Mealtime: Delicious & Customised <br></br>Lunch Box, Delivered Free!</p>

                    <div className='mt-8 '>
                        <p className='flex flex-row gap-4 mb-4 '><img src={dish} className='w-12'></img> <span className='mt-3 text-lg'>Cusomise your meals</span></p>
                        <p className='flex flex-row gap-4 mb-4'><img src={thumb} className='w-12'></img><span className='mt-3 text-lg'>We cook & deliver</span></p>
                        <p className='flex flex-row gap-4'><img src={cooking} className='w-12'></img><span className='mt-3 text-lg'>Enjoy & Repeat</span></p>
                    </div>
                </div>
                <div>
                    <img src={free} className='imgShadow'></img>
                </div>

            </div>

            <div className='flex flex-col justify-center items-center gap-12 lg:flex-row lg:justify-center lg:items-stretch'>
                <WhyCard head='Daily Order' txt='Instant homemade food order during breakfast, lunch, high tea, and dinner' image={cardImg1}></WhyCard>
                <WhyCard head='Advance Order' txt='You can order your choice of homemade food in advance for up to 2 days. Now you plan in advance for a perfect weekend.' image={cardImg2}></WhyCard>
                <WhyCard head='Regional Snacks' txt='You can order a variety of your choice regional snacks such as pickles, papad, sweets, any side snacks, etc.' image={cardImg3}></WhyCard>
                <WhyCard head='Authentic Regional Food' txt='No more cravings for regional food! Now you can get authentic regional food at your doorstep.' image={cardImg4}></WhyCard>
            </div>
        </div>
    )
}

export default Whyus;
import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import men1 from '../asset/man1.jpg'
import woman1 from '../asset/woman2.webp'
import woman3 from '../asset/woman3.jpg'
import woman2 from '../asset/woman1.avif'
import man2 from '../asset/man2.png'
import man3 from '../asset/man3.jpg'

import TestCard from "./TestCard";

function TestSlider() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div>
                    <TestCard
                        txt="The lunch menu offered a delightful array of options, and each one was expertly prepared and bursting with flavor."
                        name="Arjun" city="PCMC" image={men1}></TestCard>
                </div>
                <div>
                    <TestCard txt="I always look forward to my lunch breaks knowing I can enjoy a satisfying meal from this place; the quality never disappoints."
                        name="Ananya" city="Pune" image={woman1}></TestCard>
                </div>
                <div>
                    <TestCard txt="Incredible flavors and generous portions; lunch here is not just a meal, it's an experience"
                        name="Rahul" city="Pune" image={man2}></TestCard>
                </div>
                <div>
                    <TestCard txt="From the first bite to the last, my lunch was a symphony of taste and texture; I left feeling completely satisfied."
                        name="Neha" city="PCMC" image={woman3}></TestCard>
                </div>
                <div>
                    <TestCard txt="Effortlessly combining freshness and creativity, the lunch offerings here are simply outstanding."
                        name="Vikram" city="Pune" image={man3}></TestCard>
                </div>
                <div>
                    <TestCard txt="Lunchtime bliss; I can always count on this place for a delicious meal that never fails to impress."
                        name="Ishita" city="PCMC" image={woman2}></TestCard>
                </div>
            </Slider>
        </div>
    );
}

export default TestSlider;

import React from 'react'


const HowCard = (props) => {
    return (
        <div className='w-64 mx-auto block'>
            <img src={props.image} className='m-auto display-block'></img>
            <p className='text text-center my-4 text-xl  font-semibold'>{props.head}</p>
            <p className='text text-center font-medium'>{props.txt}</p>
        </div>
    )
}

export default HowCard;
import React, { useState } from 'react'

const Extra = ({ extraHnd, chapati, bhakri, bread }) => {
    // const [chapati, setChapati] = useState(0)
    // const [bhakri, setBhakri] = useState(0)

    const breadList = bread?.map((list) => list.dishName)

    const clkHnd = (e) => {
        if (e.target.name === 'plus') {
            extraHnd('plus', 1)
        } else {
            extraHnd('minus', -1)
        }
    }

    const clkHnd2 = (e) => {
        if (e.target.name === 'plusB') {
            extraHnd('plusB', 1)
        } else {
            extraHnd('minusB', -1)
        }
    }



    const hndChg = (e) => {
        console.log("chg")
    }



    return (
        <div className='flex flex-row justify-center gap-2 mx-4'>
            {breadList?.includes('CHAPATI -3') && <div className='flex flex-row justify-center gap-8 '>
                <p>Chapati:</p>
                <div className='flex flex-row gap-2'>
                    <button onClick={clkHnd} name='plus' className='bg-red-500 w-2/12 text-white h-full rounded-full'>+</button>
                    <input readOnly onChange={hndChg} type='text' value={chapati} defaultValue={chapati}
                        className='text-center rounded-md w-2/12 border border-solid border-gray-500'></input>
                    <button onClick={clkHnd} name='minus' className='bg-red-500 w-2/12 text-white h-full rounded-full'>-</button>
                </div>
            </div>}

            {breadList?.includes('Bhakri -2') && <div className='flex flex-row justify-center gap-8 '>
                <p>Bhakri:</p>
                <div className='flex flex-row gap-2'>
                    <button onClick={clkHnd2} name='plusB' className='bg-red-500 w-2/12 text-white h-full rounded-full'>+</button>
                    <input onChange={hndChg} type='text' value={bhakri} defaultValue={bhakri}
                        className='text-center rounded-md w-2/12 border border-solid border-gray-500'></input>
                    <button onClick={clkHnd2} name='minusB' className='bg-red-500 w-2/12 text-white h-full rounded-full'>-</button>
                </div>
            </div>}


        </div>
    )
}

export default Extra
import classes from "./MyOfferDetails.module.css";
import { useState, useEffect } from "react"
import axios from "axios";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import useRazorpay from "react-razorpay";


const MyOfferDetails = (ID) => {

    const [offerDetails, setOfferDetails] = useState();

    const offer_Details = async (ID) => {
        console.log(ID)
        try {
            const res = await axios.get("https://dlunchbox.com/offerDetails", { params: { ID } })
            console.log(res)
            setOfferDetails(res.data[0])
        } catch (err) {
            console.log(err)
        }
    };

    //handle payment
    const [Razorpay] = useRazorpay();
    const handlePOPSUBMIT = async (price, valid_days, event) => {

        console.log(price)
        console.log(valid_days)
        const Cid = Cookies.get("CID")
        const amount = price * 100;
        const currency = 'INR';
        const receiptId = uuidv4();;

        if (!Cookies.get("CID")) {
            const newTabUrl = 'https://dlunchbox.com/login';
            // Open a new tab with the specified URL
            window.open(newTabUrl, '_blank');
        } else {
            const response = await fetch('https://dlunchbox.com/order', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount,
                    currency,
                    receipt: receiptId
                })
            })

            const order = await response.json();
            console.log("order", order);

            var option = {
                key: '',
                amount,
                currency,
                name: 'D LunchBox',
                description: 'Transaction',
                image: 'https://dlunchbox.com/static/media/logo.5374deb337fb4bd1dd95.png',
                order_id: order.id,
                handler: async function (response) {
                    const body = { ...response }

                    const validateResponse = await fetch("https://dlunchbox.com/validate", {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    })

                    const jsonResponse = await validateResponse.json();

                    console.log('jsonResponse', jsonResponse);

                    if (jsonResponse) {
                        console.log(price, "PPRICE")
                        const CID = Cookies.get("CID")
                        try {
                            const walletMoney = price;
                            const res = await axios.put("https://dlunchbox.com/userOfferPlaced", { CID, walletMoney, valid_days })
                            console.log(res)
                        } catch (err) {
                            console.log(err)
                        }
                        alert("PAYMENT SUCCESSFUL")
                    }
                },
                theme: {
                    color: "#3399cc"
                },
            }

            var rzp1 = new Razorpay(option);
            rzp1.on("payment.failed", function (response) {
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
                alert(response.error.metadata.order_id);
                alert(response.error.metadata.payment_id);
            })

            rzp1.open();
            //event.preventDefault();
        }
    }

    useEffect(() => {
        let ID = Cookies.get('my_offers')
        offer_Details(ID)
    }, []);
    return (
        <>
            <div className={classes.container}>

                <div className={classes.top}>
                    <div className={classes.top_left}>
                        <h1 className={classes.head}>{offerDetails?.DESC_HEAD}</h1>
                        <p className={classes.price}>Offer Price : ₹ {offerDetails?.PRICE}</p>
                        <p className={classes.details}>Offer Details :
                            {offerDetails?.FULL_DESC}</p>
                    </div>
                    <div className={classes.top_right}>
                        {offerDetails?.PRICE !== "50" && <button className={classes.buynowbtn} onClick={() => handlePOPSUBMIT(offerDetails?.PRICE, offerDetails?.VALID_DAYS)}>Buy Now</button>}
                    </div>
                </div>

                <div className={classes.bottom}>
                    <h2>Terms and Conditions:</h2>

                    <h3>Eligibility:</h3>
                    <p>{offerDetails?.ELIGIBILITY}</p>

                    <h3>Offer Period:</h3>
                    <p>{offerDetails?.OFFER_PERIOD}</p>

                    <h3>Subscription Details:</h3>
                    <p>{offerDetails?.SUBSCRIPTION_DETAILS}</p>

                    <h3>Payment:</h3>
                    <p>{offerDetails?.PAYMENT}</p>

                    <h3>Usage:</h3>
                    <p>{offerDetails?.USAGE}</p>

                    <h3>Cancellation and Refunds:</h3>
                    <p>{offerDetails?.CAN_REFUND}</p>

                    <h3>Service Availability:</h3>
                    <p>{offerDetails?.SERV_AVAILIBILITY}</p>
                </div>
            </div>
        </>
    );
};
export default MyOfferDetails;
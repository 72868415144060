import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import classes from './Signup.module.css';
import Signuppopup from './Signuppopup';
import Signuppopup2 from './Signuppopup2';
import i from './asset/img4.jpg';

const Signup = () => {
    const [data, setData] = useState({
        cid: uuidv4(),
        name: "",
        mobile: "",
        email: "",
        password: "",
        confirmPass: ""
    });
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupOpen2, setIsPopupOpen2] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for showing password
    const navigate = useNavigate();

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const closePopup2 = () => {
        setIsPopupOpen2(false);
        navigate("/");
    };

    const hndChg = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword); // Toggle the state to show/hide password
    };

    const hndSbt = async (e) => {
        e.preventDefault();
        setIsPopupOpen(true);

        if (data.password !== data.confirmPass) {
            setIsPopupOpen(false);
            alert("Password mismatch");
            return;
        }

        try {
            const res = await axios.post("https://dlunchbox.com/signup", { data });
            console.log(res);

            if (res.status === 200) {
                setIsPopupOpen(false);
                setIsPopupOpen2(true);
            }
        } catch (error) {
            if (error.response) {
                setIsPopupOpen(false);
                alert(error.response.data);
            } else {
                alert('An error occurred while signing up.');
            }
        }
    };

    return (
        <div>
            <p className="mt-28 mb-12 text-5xl font-bold text-center multicolor">Register</p>
            <div className={classes.container}>
                <div className={classes.imageWrapper}>
                    <img src={i} alt="Image" className={classes.image} />
                </div>
                <div className={classes.formWrapper}>
                    <form onSubmit={hndSbt}>
                        <input name='name' onChange={hndChg} type='text' placeholder='Name' className={classes.input} required></input><br></br>
                        <input name='mobile' onChange={hndChg} type='number' placeholder='Mobile Number' className={classes.input} required></input><br></br>
                        <input name='email' onChange={hndChg} type='email' placeholder='Email Id' className={classes.input} required></input><br></br>
                        <input name='password' onChange={hndChg} type={showPassword ? 'text' : 'password'} placeholder='Password' className={classes.input} required></input><br></br>
                        <input name='confirmPass' onChange={hndChg} type={showPassword ? 'text' : 'password'} placeholder='Confirm Password' className={classes.input} required></input><br></br>
                        {/* Checkbox for showing password */}
                        <label className={classes.checkboxLabel}>
                            <input type="checkbox" onChange={togglePasswordVisibility} /> Show Password
                        </label><br />
                        <button type='submit' className={classes.button}>Submit</button>
                        <p className={classes.signupText}>Already have an account? <Link to='/login'><span className={classes.signupLink}>Login</span></Link></p>
                    </form>
                </div>
                {isPopupOpen && <Signuppopup onClose={closePopup} />}
                {isPopupOpen2 && <Signuppopup2 onClose2={closePopup2} />}
            </div>
        </div>

    );
};

export default Signup;

import React, { useEffect, useState } from 'react'
import classes from './AdminDashboard.module.css';
import axios from 'axios'
import { Link } from 'react-router-dom'

import NewOrder from './NewOrder'
import ConfirmOrders from './ConfirmOrders'
import DeliveredOrders from './DeliveredOrders'
import Kitchen from './Kitchen'
import Pincode from './Pincode'

import NewKitchen from './NewKitchen';

const AdminDashboard = () => {
    const [newOrders, setNewOrders] = useState(false)
    const [confirmOrders, setConfirmOrders] = useState(false)
    const [deliveredOrders, setDeliveredOrders] = useState(false)
    const [kitchenOrders, setKitchenOrders] = useState(false)
    const [pincode, setPincode] = useState(false)
    const [btn1, setBtn1] = useState(false)
    const [btn2, setBtn2] = useState(false)


    const btnHnd = (e) => {
        if (e.target.value === 'new order') {
            localStorage.setItem("menu", "new")
            setNewOrders(true)
            setDeliveredOrders(false)
            setConfirmOrders(false)
            setKitchenOrders(false)
            setPincode(false)
        }

        if (e.target.value === 'delivered order') {
            localStorage.setItem("menu", "delivery")
            setNewOrders(false)
            setDeliveredOrders(true)
            setConfirmOrders(false)
            setKitchenOrders(false)
            setPincode(false)
        }

        if (e.target.value === 'confirm order') {
            localStorage.setItem("menu", "confirm")
            setNewOrders(false)
            setDeliveredOrders(false)
            setConfirmOrders(true)
            setKitchenOrders(false)
            setPincode(false)
        }

        if (e.target.value === 'kitchen') {
            localStorage.setItem("menu", "kitchen")
            setNewOrders(false)
            setDeliveredOrders(false)
            setConfirmOrders(false)
            setKitchenOrders(true)
            setPincode(false)
        }

        if (e.target.value === 'pincode') {
            localStorage.setItem("menu", "pincode")
            setNewOrders(false)
            setDeliveredOrders(false)
            setConfirmOrders(false)
            setKitchenOrders(true)
            setPincode(true)
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='mt-24'>
            <p className='text-center text-3xl font-semibold'>Admin Dashboard</p>

            <div className='flex flex-row justify-center gap-4 mt-8 bg-gray-200 py-4 sticky top-20'>
                {/* <button onClick={btnHnd} value='new order' className={`w-2/12 h-12  rounded-md ${localStorage.getItem("menu") === "new" ? 'bg-blue-200 border border-solid border-blue-700' : 'bg-orange-500'}`}>New Orders</button> */}
                <button onClick={btnHnd} value='confirm order' className={`w-2/12 h-12  rounded-md ${localStorage.getItem("menu") === "confirm" ? 'bg-green-200 border border-solid border-green-700' : 'bg-orange-500'}`}>Datewise Orders</button>
                <button onClick={btnHnd} value='kitchen' className={`w-2/12 h-12  rounded-md ${localStorage.getItem("menu") === "kitchen" ? 'bg-violet-200' : 'bg-orange-500'}`}>Kitchen</button>
                <button onClick={btnHnd} value='delivered order' className={`w-2/12 h-12  rounded-md ${localStorage.getItem("menu") === "delivery" ? 'bg-green-600' : 'bg-orange-500'}`}>Delivered Orders</button>

                <button onClick={btnHnd} value='pincode' className={`w-2/12 h-12  rounded-md ${pincode ? 'bg-blue-200' : 'bg-orange-500'}`}>Pincode List</button>
                <div className={classes.dropdown}>
                    <button className={classes.menu}>More</button>
                    <div className={classes.dropdownContent}>
                        <Link to='/menuPage'><p className={classes.drop}>Menu</p></Link>
                        <Link to='/usersList'><p className={classes.drop}>Users List</p></Link>
                        <Link to='/wallet'><p className={classes.drop}>Wallet</p></Link>
                        <Link to='/password'><p className={classes.drop}>Reset Password</p></Link>
                        <Link to='/DeliveryPins'><p className={classes.drop}>Set Pincodes</p></Link>
                        {/* <p className={classes.drop}>abc</p> */}
                    </div>
                </div>
            </div>

            <div className=''>

                {localStorage.getItem("menu") === "new" && <NewOrder></NewOrder>}
                {localStorage.getItem("menu") === "confirm" && <ConfirmOrders></ConfirmOrders>}
                {localStorage.getItem("menu") === "delivery" && <DeliveredOrders></DeliveredOrders>}
                {localStorage.getItem("menu") === "kitchen" && <NewKitchen></NewKitchen>}
                {localStorage.getItem("menu") === "pincode" && <Pincode></Pincode>}

            </div>
        </div >
    )
}

export default AdminDashboard
import React, { useEffect, useState } from 'react'
import axios from 'axios'

const NewKitchen = () => {
    const [date, setDate] = useState()
    const [data, setData] = useState()
    const [data2, setData2] = useState()
    const [lunch, setLunch] = useState(false)
    const [dinner, setDinner] = useState(false)
    const [extraLunch, setExtraLunch] = useState()
    const [extraDinner, setExtraDinner] = useState()

    const [lunchList, setLunchList] = useState()
    const [dinnerList, setDinnerList] = useState()

    const getDateWiseDinnerList = async (dateValue) => {
        try {
            const res = await axios.get("https://dlunchbox.com/dynamicList", { params: { dateValue } })
            console.log(res.data, " dyn")
            setDinnerList(res.data)

        } catch (err) {
            console.log(err)
        }
    }

    const getDateWiseLunchList = async (dateValue) => {
        try {
            const res = await axios.get("https://dlunchbox.com/dynamicLunchList", { params: { dateValue } })
            console.log(res.data, " dyn")
            setLunchList(res.data)

        } catch (err) {
            console.log(err)
        }
    }

    const getDateWiseCards = async (dateValue) => {

        try {
            const res = await axios.get("https://dlunchbox.com/dateWiseConfirmOrderList", { params: { dateValue } })

            setData2(res.data)
            console.log(res.data, " cards")
        } catch (err) {
            console.log(err)
        }
    }

    const getDateWiseExtraLunch = async (dateValue) => {
        const res = await axios.get("https://dlunchbox.com/getExtraBreadsLunch", { params: { dateValue } })

        setExtraLunch(res.data[0])
    }

    const getDateWiseExtraDinner = async (dateValue) => {
        const res = await axios.get("https://dlunchbox.com/getExtraBreadsDinner", { params: { dateValue } })

        setExtraDinner(res.data[0])
    }

    function handleDateChange(event) {
        console.log(event.target.value, " date")
        setDate(event.target.value);
        getDateWiseDinnerList(event.target.value)
        getDateWiseLunchList(event.target.value)
        getDateWiseCards(event.target.value)
        getDateWiseExtraLunch(event.target.value)
        getDateWiseExtraDinner(event.target.value)
    }

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Adding leading zeros if month or day are less than 10
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }


    useEffect(() => {


    }, [])

    const readyHnd = async (orderid) => {

        try {
            const res = await axios.post("https://dlunchbox.com/outForDelivery", { orderid })
            if (res.status === 200) {
                console.log('200 delivery')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const lunchHnd = () => {
        setLunch(true)
        setDinner(false)
        console.log(Lunch, " lunch")
    }

    const dinnerHnd = () => {
        setLunch(false)
        setDinner(true)
        console.log(Dinner, " dinner")
    }

    const dinnerFilteredList = dinnerList?.filter(list => list.total_orders > 0)
    const lunchFilteredList = lunchList?.filter(list => list.total_orders > 0)


    const Lunch = data2?.filter(order => !order.deliverytime.includes('7'))
    const Dinner = data2?.filter(order => order.deliverytime.includes('7'))

    const lunchVeg = Lunch?.filter(order => order.mealtype === 'veg')
    const lunchNonVeg = Lunch?.filter(order => order.mealtype === 'nonVeg')

    const dinnerVeg = Dinner?.filter(order => order.mealtype === 'veg')
    const dinnerNonVeg = Dinner?.filter(order => order.mealtype === 'nonVeg')



    return (
        <div className='bg-blue-200 pb-8'>
            <div className=''>
                <div className='flex flex-row justify-center gap-12 py-4 bg-blue-200'>
                    <label>Select A Date:</label>
                    <input onChange={handleDateChange} id="date" type='date' name="date" value={date}
                        className='border border-solid border-blue-800 w-48 text-center'></input>
                </div>
            </div>

            <div className='flex flex-row justify-center gap-8 mb-4'>
                <button onClick={lunchHnd} className={`px-4 py-2 rounded ${lunch ? 'bg-green-500 text-white' : 'bg-gray-400 text-black'
                    }`}>Lunch</button>
                <button onClick={dinnerHnd} className={`px-4 py-2 rounded ${dinner ? 'bg-green-500 text-white' : 'bg-gray-400 text-black'
                    }`}>Dinner</button>
            </div>

            {<div className='flex flex-row justify-center gap-8 mb-4 bg-white w-72 mx-auto left-0 right-0 rounded-md py-2'>
                <p className='text-center'><b>Veg:</b> {lunch ? lunchVeg?.length : dinnerVeg?.length}</p>
                <p className='text-center'><b>nonVeg:</b> {lunch ? lunchNonVeg?.length : dinnerNonVeg?.length}</p>
            </div>}
            <div className='bg-white mx-auto left-0 right-0 w-11/12 rounded-md flex flex-row flex-wrap justify-center gap-8 py-4'>
                {dinner && dinnerFilteredList?.map((list, index) => {
                    return <div key={index} className='border border-solid border-gray-400 px-4   rounded-md' >
                        <p>{list.dishName}:
                            <span> {list.total_orders}</span></p>
                    </div>
                })}

                {lunch && lunchFilteredList?.map((list, index) => {
                    return <div key={index} className='border border-solid border-gray-400 px-4   rounded-md'>
                        <p>{list.dishName}:
                            <span> {list.total_orders}</span></p>
                    </div>
                })}
            </div>

            <div className='mt-2 bg-white w-11/12 mx-auto left-0 right-0 rounded-md flex flex-row justify-center gap-8 py-1'>
                {lunch && <p className='border border-solid border-gray-400 px-4   rounded-md'>Extra Chapati: {extraLunch?.total_extraChapati} nos</p>}
                {lunch && <p className='border border-solid border-gray-400 px-4   rounded-md'>Extra Bhakri: {extraLunch?.total_extraBhakri} nos</p>}
                {dinner && <p className='border border-solid border-gray-400 px-4   rounded-md'>Extra Chapati: {extraDinner?.total_extraBhakri} nos</p>}
                {dinner && <p className='border border-solid border-gray-400 px-4   rounded-md'>Extra Bhakri: {extraDinner?.total_extraBhakri} nos </p>}
            </div>

            {lunch && <div>
                {Lunch?.map((order, index) => {
                    return <div key={index} >
                        <div className='w-10/12 bg-gray-200 my-8 py-4 mx-auto block border border-blue-700 rounded-md'>

                            <div className='flex flex-row justify-center gap-20 mb-4 mx-2 bg-gray-300 p-1 rounded-md'>
                                <p>no.{order.id}</p>
                                <p>cust Id: <span className=''>{order.cid}</span></p>
                                <p>order Id: <span className=''>{order.orderid}</span></p>
                                <p><span className='text-xl'>{order.mealtype}</span></p>
                            </div>

                            <div className='flex flex-row justify-center gap-12 bg-gray-300 mx-2 rounded-md py-1'>
                                <p>Name: <span className='text-xl font-semibold'>{order.name}</span></p>
                                <p>Mobile: <span className='text-xl font-semibold'>{order.mobno}</span></p>
                            </div>

                            <div className='flex flex-row justify-center gap-12 mb-4 bg-gray-300 mx-2 rounded-md py-1'>
                                <p>Address: <span className='text-xl font-semibold'>{order.deliveryaddress}</span></p>
                                <p>city: <span className='text-xl font-semibold'>{order.city}</span></p>
                                <p>landmark: <span className='text-xl font-semibold'>{order.landmark}</span></p>
                                <p>pincode: <span className='text-xl font-semibold'>{order.pincode}</span></p>
                            </div>

                            <div className=' flex flex-row justify-center mb-4 bg-gray-300 mx-2 rounded-md py-1'>
                                <p>Delivery Time: <span className='text-xl font-semibold'>{order.deliverytime}</span></p>
                            </div>



                            <p className='shadow-xl text-xl text-center rounded-md pt-1 bg-gray-300 mx-2'>Lunch Box: <span className='text-xl font-semibold'>{order.rice}, {order.vegetable1}, {order.vegetable2}, {order.bread}, {order.sweet}</span></p>
                            <div className='shadow-xl flex flex-row justify-center gap-12 bg-gray-300 mx-2 rounded-md'>
                                <p className='text-xl font-semibold'>extra Chapati: {extraLunch.total_extraChapati}</p>
                                <p className='text-xl font-semibold'>extra Bhakri: {extraLunch.total_extraBhakri}</p>
                            </div>
                            <p className='text-center mt-2'><b>Quantity: </b>{order.quantities}</p>

                            <div className='flex flex-row justify-center gap-8 mt-4'>
                                {/* <button className='bg-red-500 w-36 h-12 rounded-lg'>Cancel</button> */}
                                <button onClick={() => { readyHnd(order.orderid) }} className='bg-green-500 w-36 h-12 rounded-lg'>Order Ready</button>
                            </div>

                        </div>
                    </div>
                })}
            </div>}


            {dinner && <div>
                {Dinner?.map((order, index) => {
                    return <div key={index} >
                        <div className='w-10/12 bg-violet-300 my-8 py-4 mx-auto block border border-blue-700 rounded-md'>

                            <div className='flex flex-row justify-center gap-20 mb-4'>
                                <p>no.{order.id}</p>
                                <p>cust Id: <span className=''>{order.cid}</span></p>
                                <p>order Id: <span className=''>{order.orderid}</span></p>
                                <p><span className='text-xl'>{order.mealtype}</span></p>
                            </div>

                            <div className='flex flex-row justify-center gap-12 mb-8'>
                                <p>Name: <span className='text-2xl font-medium'>{order.name}</span></p>
                                <p>Mobile: <span className='text-2xl font-medium'>{order.mobno}</span></p>
                            </div>

                            <div className='flex flex-row justify-center gap-12 mb-8'>
                                <p>Address: <span className='text-2xl font-medium'>{order.deliveryaddress}</span></p>
                                <p>city: <span className='text-2xl font-medium'>{order.city}</span></p>
                                <p>landmark: <span className='text-2xl font-medium'>{order.landmark}</span></p>
                                <p>pincode: <span className='text-2xl font-medium'>{order.pincode}</span></p>
                            </div>

                            <div className='flex flex-row justify-center mb-8'>
                                <p>Delivery Time: <span className='text-2xl font-medium'>{order.deliverytime}</span></p>
                            </div>



                            <p className='text-xl text-center'>Lunch Box: <span className='text-2xl font-medium'>{order.rice}, {order.vegetable1}, {order.vegetable2}, {order.bread}, {order.sweet}</span></p>
                            <p className='text-center mt-2'><b>Quantity: </b>{order.quantities}</p>

                            <div className='flex flex-row justify-center gap-8 mt-8'>
                                {/* <button className='bg-red-500 w-36 h-12 rounded-lg'>Cancel</button> */}
                                <button onClick={() => { readyHnd(order.orderid) }} className='bg-green-500 w-36 h-12 rounded-lg'>Order Ready</button>
                            </div>

                        </div>
                    </div>
                })}
            </div>}

        </div>
    )
}

export default NewKitchen;
import React from 'react'

import img1 from './asset/whyCard1.png'

const WhyCard = (props) => {
    return (
        <div className='shadow-2xl w-60 border border-transparent rounded-xl'>
            <img src={props.image} className='p-4'></img>
            <p className='text-xl font-bold text-center mb-4 mt-8'>{props.head}</p>
            <p className='mx-4 pb-4'>{props.txt}</p>
        </div>
    )
}

export default WhyCard;
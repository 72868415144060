import React from 'react';
import './SubmitPopup.css';
//#ab2564  #5f276e
const SubmitPopup = ({ str, onClose }) => {
    return (
        <div className="submitPopup-container">
            <div className="submitPopup">
                <p>{str}</p>

                {str = "Order Added To Cart Successfully...ThankYou" && <button onClick={onClose} className='bg-red-600 mx-auto block my-4 w-4/12 h-12 rounded-md text-white'>OK</button>}

            </div>


        </div>
    );
};

export default SubmitPopup;
import React, { useEffect } from 'react';
import './About.css'
import Inside from '../components/about/Inside';

import comma from './asset/comma.png';

const Aboutpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='my-16 '>
            <div className='abtHd py-8'>
                <p className='text-4xl pt-4 text-center text-white' >It's all about great food</p>
                <p className='text-white text-center mt-4 text-xl'>amazing food delivered all over Pune & PCMC</p>
            </div>

            <div className='mt-8 w-8/12 mx-auto'>
                <p className='text-center text-5xl'>About The DLunch Box</p>

                <p className='text-center mt-8 text-lg'>DLunchBox is a venture of Bharekar & Brothers Multi Management Services LLP.</p>
                <p className='text-center text-lg'>We are in Food & Catering services since 2021 & running successfully a chain of restaurant's
                    in Pune</p>
                <p className='text-center text-lg'>Currently, we are launching a lunch box called DLunchBox, which ensure every individual a good quality & cost effective mid-day meal
                    to every working individual as well as we cater to each and every individual who loves to food.</p>

                <p className='text-center text-lg'>Dr. Rupali Sanas, Mr. Rushikesh Bharekar & Mr. Amit Sanas are our Founders & Partners.</p>

                <p className='text-center text-lg'>Mr. Amit Sanas is handling main role, he is a concept designer for our organisation & also he is heading as a CEO of the organisation</p>

                <p className='text-center text-lg'>Mr.Rushikesh Bharekar is handling & heading a quality production procedure.</p>

                <p className='text-center text-lg'>Dr.Rupali is handling & heading a Administration of the organisation</p>
            </div>

            <div className='bg-orange-500 text-white text-center py-12 mt-12 '>
                <div className='bg-orange-500 flex flex-row justify-center gap-12 mb-8'>
                    <img src={comma} className='w-12'></img>
                    <p className='text-center text-white text-5xl mt-4'> Our Vision</p>
                </div>

                <p className='mx-32 text-2xl'>We have started DLunchBox services in all over pune city & PCMC city from 1st May 2024 & we will assure you that within 30 days we will occupy both the cities to achieve the targets.</p>
                <p className='mx-32 text-2xl mt-4'>We will start catering new cities from August 2024 & till end of this year (2024), we will make impact in atleast 5 cities of Maharashtra.</p>
            </div>

            <Inside></Inside>
        </div>
    )
}

export default Aboutpage;



















// import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
// import axios from 'axios'

// const Aboutpage = () => {
//     const [data, setData] = useState()
//     const navigate = useNavigate()

//     axios.defaults.withCredentials = true

//     const getVerify = async () => {
//         try {
//             const res = await axios.get("https://dlunchbox.com/about")
//             if (res.data === "success") {
//                 setData("verified")
//             } else {
//                 navigate("/login")
//             }
//         } catch (err) {
//             console.log(err)
//         }
//     }
//     useEffect(() => {
//         getVerify()
//     }, [])
//     return (
//         <div>Aboutpage
//             <p>{data}</p>
//         </div>
//     )
// }

// export default Aboutpage;
import React from 'react'
import TestSlider from './TestSlider';

import TestCard from './TestCard';

const Test = () => {
    return (
        <div className='bg-green-700 py-4 pb-28  overflow-hidden'>
            <p className='text-center text-white text-4xl font-semibold pt-20'>What our customer's say</p>
            <p className='text-center text-white text-4xl font-semibold mb-16'>about us</p>

            <TestSlider className=''></TestSlider>
        </div>
    )
}

export default Test;
import React, { useState } from 'react'
import axios from 'axios'

const WalletHistory = () => {
    const [data, setData] = useState()
    const [mobile, setMobile] = useState()
    const [isSubmitting, setIsSubmiting] = useState(false)

    const hndSbt = async (e) => {
        e.preventDefault()

        console.log(mobile, " mob")

        try {
            setIsSubmiting(true)
            const res = await axios.get("https://dlunchbox.com/adminGetWalletHist", { params: { mobile } })

            if (res.status == 200) {
                setData(res.data)
                setIsSubmiting(false)
                //alert('data')
            } else if (res.status == 500) {
                alert('network issue')
                setIsSubmiting(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const hndChg = (e) => {
        setMobile(e.target.value)
    }

    return (
        <div>
            <p className='text-center text-3xl font-semibold text-orange-600'>Wallet History</p>

            <p className='text-center'>Use mobile number of user to populate his/her wallet history</p>
            <div className='mt-4 mx-auto block w-4/12 bg-orange-200 py-4 px-4 rounded-lg'>
                <form onSubmit={hndSbt}>
                    <input onChange={hndChg} className='border rounded-lg w-full h-12 text-center border-solid' placeholder='mobile number' type='number'></input>
                    <button disabled={isSubmitting}
                        className={`px-4  rounded-lg w-full h-12 mt-8 ${isSubmitting ? 'bg-gray-300' : 'bg-orange-600'}`} type='submit'>{isSubmitting ? 'wait..' : "Submit"}</button>
                </form>
            </div>
            <hr></hr>

            <div>
                <p className='text-center mt-8 mb-4'>Details:</p>
                {data?.map((data, index) => {
                    return <div key={index} className='flex flex-row gap-8 justify-start border border-solid border-gray-300 mb-2'>
                        <p className='ml-4'>{index + 1}:</p>
                        <p className={`${data?.type == 'added' ? 'text-green-600' : 'text-red-500'}`}>{data.mobno}</p>
                        <p className={`${data?.type == 'added' ? 'text-green-600' : 'text-red-500'}`}>Rs. {data.amount}</p>
                        <p className={`${data?.type == 'added' ? 'text-green-600' : 'text-red-500'}`}>{data.type}</p>
                        <p className={`${data?.type == 'added' ? 'text-green-600' : 'text-red-500'}`}>{data.time_stamp}</p>
                    </div>
                })}
            </div>
        </div>
    )
}

export default WalletHistory;
import React, { useState } from 'react'
import axios from 'axios'

const Password = () => {
    const [data, setData] = useState({
        oldPass: "",
        newPass: "",
        confirmPass: ""
    })

    const [submitting, setSubmitting] = useState(false)
    const [checked, setChecked] = useState(false)

    const hndChg = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const hndSubmit = async (e) => {
        e.preventDefault()


        if (data.newPass !== data.confirmPass) {
            alert("New Password and Confirm Password not Match")

            return;
        }

        try {
            setSubmitting(true)
            const resp = await axios.post("https://dlunchbox.com/chgPassword", data)

            if (resp.status == 200) {
                alert("pass chg success")
                setSubmitting(false)
                window.location.reload()
            } else {
                alert('old password not correct')
                setSubmitting(false)
            }
        } catch (err) {
            //console.log(err)
            alert('Please Insert Correct Old Password')
            setSubmitting(false)
        }


    }

    return (
        <div className='mt-24 '>
            <p className='text-center text-2xl font-semibold'>Change Password</p>

            <div className='w-5/12 mx-auto block mt-8 bg-gray-200 py-8 px-8 rounded'>
                <form onSubmit={hndSubmit} className='flex flex-col justify-center'>
                    <input onChange={hndChg} name='oldPass' type={checked ? 'text' : 'password'} placeholder='old password' className='border border-solid border-gray-600 mb-8 rounded'></input>
                    <input onChange={hndChg} name='newPass' type={checked ? 'text' : 'password'} placeholder='New Password' className='border border-solid border-gray-600 mb-8 rounded'></input>
                    <input onChange={hndChg} name='confirmPass' type={checked ? 'text' : 'password'} placeholder='Confirm Password' className='border border-solid border-gray-600 rounded'></input>
                    <div className='mt-4 mx-auto block w-7/12'>
                        <input type='checkbox' onChange={() => { setChecked(!checked) }}></input>
                        <label className='text-center'>Show Password</label>
                    </div>
                    <button type='submit' disabled={submitting} className={` ${submitting ? "bg-gray-500" : "bg-red-600"} mt-8 rounded py-2`}>{submitting ? "wait..." : "Submit"}</button>
                </form>
            </div>
        </div>
    )
}

export default Password
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Slippopup from './Slippopup';


const DeliveredOrders = () => {
    const [date, setDate] = useState(getTodayDate())
    const [data, setData] = useState()
    const [slip, setSlip] = useState()
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const closePopup = () => {
        setIsPopupOpen(false);

    };

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        let day = today.getDate();

        // Adding leading zeros if month or day are less than 10
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    }

    function handleDateChange(event) {
        setDate(event.target.value);
        getDateWiseList(event.target.value)
    }

    const getDateWiseList = async (dateValue) => {
        //console.log(dateValue, " date")
        const token = localStorage.getItem("token")

        try {
            const res = await axios.get("https://dlunchbox.com/dateWiseDeliveredOrderList", {
                params: { dateValue },
                headers: {
                    Authorization: token,
                },
            })
            console.log(res.data)
            setData(res.data)



        } catch (err) {
            console.log(err)
        }
    }

    const getOrderList = async () => {
        const dateValue = getTodayDate()
        const token = localStorage.getItem("token")

        try {
            const res = await axios.get("https://dlunchbox.com/dateWiseDeliveredOrderList", {
                params: { dateValue },
                headers: {
                    Authorization: token,
                },
            })
            setData(res.data)
        } catch (err) {
            console.log(err)
        }
    }


    useEffect(() => {
        getOrderList()
    }, [])


    // const slipHnd = async (cid, orderid, sweet, rice, bread, veg1, veg2) => {
    //     try {
    //         const res = await axios.get("https://dlunchbox.com/adminSlip", { params: { cid, orderid, sweet, rice, bread, veg1, veg2 } })
    //         console.log(res.data)
    //         setSlip(res.data[0])
    //     } catch (err) {
    //         console.log(err)
    //     }
    //     setIsPopupOpen(true)
    // }

    const slipHnd = (order) => {
        setSlip(order)
        setIsPopupOpen(true)

    }

    return (
        <div className='bg-green-100 py-8'>
            <div className=''>
                <div className='flex flex-row justify-center gap-12 py-4 bg-blue-200'>
                    <label>Select A Date:</label>
                    <input onChange={handleDateChange} id="date" type='date' name="date" value={date}
                        className='border border-solid border-blue-800 w-40 text-center'></input>
                </div>
            </div>
            <p className='text-center text-3xl mb-4 font-medium'>Orders Delivery List</p>

            {data?.map((order, index) => {
                return <div key={index} >
                    <div className='w-10/12 bg-green-300 my-8 py-4 mx-auto block border border-blue-700 rounded-md'>

                        <div className='flex flex-row justify-center gap-20 mb-4'>
                            <p>no.{index + 1}</p>
                            <p>cust Id: <span className=''>{order.cid}</span></p>
                            <p>order Id: <span className=''>{order.orderid}</span></p>
                            <p><span className='text-xl'>{order.mealtype}</span></p>
                        </div>

                        <div className='flex flex-row justify-center gap-12 mb-8'>
                            <p>Name: <span className='text-2xl font-medium'>{order.name}</span></p>
                            <p>Mobile: <span className='text-2xl font-medium'>{order.mobno}</span></p>
                        </div>

                        <div className='flex flex-row justify-center gap-12 mb-8'>
                            <p>Address: <span className='text-2xl font-medium'>{order.deliveryaddress}</span></p>
                            <p>city: <span className='text-2xl font-medium'>{order.city}</span></p>
                            <p>landmark: <span className='text-2xl font-medium'>{order.landmark}</span></p>
                            <p>pincode: <span className='text-2xl font-medium'>{order.pincode}</span></p>
                        </div>



                        <p className='text-xl text-center'>Lunch Box: <span className='text-2xl font-medium'>{order.rice}, {order.vegetable1}, {order.vegetable2}, {order.bread}, {order.sweet}</span></p>

                        <div className='flex flex-row justify-center gap-8 mt-8'>
                            {/* <button className='bg-red-500 w-36 h-12 rounded-lg'>Cancel</button> */}
                            <button onClick={() => { slipHnd(order) }} className='bg-red-600 text-white w-36 h-12 rounded-lg'>Generate Bill</button>
                        </div>

                    </div>
                </div>
            })}


            {isPopupOpen && <Slippopup onClose={closePopup}
                name={slip?.name} mob={slip?.mobno} address={slip?.deliveryaddress}
                landmark={slip?.landmark} pincode={slip?.pincode} city={slip?.city} />}

        </div>
    )
}

export default DeliveredOrders;
import axios from "axios";
import { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import classes from "./UserList.module.css"

const UserList = () => {
    const [infoUser, setInfoUser] = useState([]);

    const get_user_info = async () => {
        const token = localStorage.getItem("token")
        try {
            const res = await axios.get('https://dlunchbox.com/admin_user_info', {
                headers: {
                    Authorization: token,
                },
            });
            setInfoUser(res.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        get_user_info();
    }, []);

    const exportToXLS = () => {
        const worksheet = XLSX.utils.json_to_sheet(infoUser);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'UserInfo');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.ms-excel;charset=UTF-8' });
        saveAs(dataBlob, 'UserInfo.xls');
    };

    return (
        <div className={classes.mainContainer}>
            <h1>User Details:</h1>
            <button onClick={exportToXLS} className='bg-green-500 mb-4 px-2 py-2 rounded-md'>Covert To Excel</button>
            <table className={classes.tableContainer}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Password</th>
                        <th>CID</th>
                    </tr>
                </thead>
                <tbody>
                    {infoUser.map((item, index) => (
                        <tr key={index}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.mobno}</td>
                            <td>{item.pass}</td>
                            <td>{item.cid}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserList;
